<div class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" [class.show]="isEnquiryList">
  <div class="modal-dialog custom-modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong> Enquiry List </strong></p>
        <img class="img-move" src="../../assets/images/icons8-cancel.svg" (click)="close()">
      </div>
      <div class="modal-body">
        <div *ngIf="loader">
          <div class="d-flex justify-content-center" style="color: #4682B4;">
            <app-common-icon [loadingIcon]="loader"></app-common-icon>
          </div>
        </div>

        <div class="card mb-3" *ngFor="let enquiry of enquiryList">
          <div class="row g-0">
            <div class="col-md-4 image">
              <ng-container *ngIf="enquiry?.user?.profileUrl?.objectUrl; else nameInitial">
                <img [src]="enquiry?.user?.profileUrl?.objectUrl">
              </ng-container>
              <ng-template #nameInitial>
                <div class="name-initial">{{ getInitials(enquiry?.user?.name) }}</div>
              </ng-template>
              <p class="name">{{enquiry?.user?.name}}</p>
              <p class="mobile">+91-{{enquiry?.phoneNumber}}</p>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <p class="card-text" style="margin-left: 220px; margin-bottom: none; font-size: 12px;">{{enquiry?.created | date:"dd MMM yy" }}</p>
                <p class="card-text">{{enquiry?.message}}</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!loader && enquiryList?.length === 0">
          <div class="pt-3 text-danger d-flex justify-content-center">
            No List Found!
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
