import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrl: './customer-reviews.component.scss'
})
export class CustomerReviewsComponent {

  data = [
    [{
      "text": "I could probably go into sales for you. I am completely blown away. After using  SEO my business skyrocketed! The very best.",
      "image": "../../assets/images/person1.png",
      "name": "Grace Hall"
    },
    {
      "text": "I received very good care at Clinicity Clinic, and the doctor took the time to explain the medications he was prescribing and why.",
      "image": "../../assets/images/person2.png",
      "name": "Connor Walker"
    },
    {
      "text": "I have insurance and still go to this clinic. The cost is in line with my copay and I don’t need to make an appointment.",
      "image": "../../assets/images/person3.png",
      "name": "Kira Wood"
    }],
    [{
      "text": "I could probably go into sales for you. I am completely blown away. After using  SEO my business skyrocketed! The very best.",
      "image": "../../assets/images/person1.png",
      "name": "Grace Hall"
    },
    {
      "text": "I received very good care at Clinicity Clinic, and the doctor took the time to explain the medications he was prescribing and why.",
      "image": "../../assets/images/person2.png",
      "name": "Connor Walker"
    },
    {
      "text": "I have insurance and still go to this clinic. The cost is in line with my copay and I don’t need to make an appointment.",
      "image": "../../assets/images/person3.png",
      "name": "Kira Wood"
    }],
    [{
      "text": "I received very good care at Clinicity Clinic, and the doctor took the time to explain the medications he was prescribing and why.",
      "image": "../../assets/images/person2.png",
      "name": "Connor Walker"
    },
    {
      "text": "I have insurance and still go to this clinic. The cost is in line with my copay and I don’t need to make an appointment.",
      "image": "../../assets/images/person3.png",
      "name": "Kira Wood"
    }]
  ];

  activeIndex = 0;
  itemsPerPage = 3;

  get paginatedData() {
    const start = this.activeIndex * this.itemsPerPage;
    return this.data.slice(start, start + this.itemsPerPage);
  }

}
