<div class="mainblock">
    <div class="col add-property-mainblock">
        <div class="properties-heading-block">
            <div class="properties-heading">Add Property</div>
            <div class="heading-border"></div>
        </div>
        <div class="property-details">
            <div class="property-details-title">Property Details</div>
            <div>
                <form [formGroup]="addpropertyFormGroup">
                    <div class="d-flex  addproperty-blockone">
                        <div>
                            <div class="inputField__label">
                                Asset Type <span style="color:red;">*</span>
                            </div>
                            <select class="addProperty__dropdownOptions" formControlName="asset">
                                <option value="" selected disabled class="addProperty-dropdown-options"
                                    style="color: gray;">
                                    Select Asset Type
                                </option>
                                <option *ngFor="let assetsList of assetsList" [ngValue]="assetsList?._id">
                                    {{assetsList.name}}
                                </option>
                            </select>
                            <div class="errorText"
                                *ngIf="addpropertyFormGroup.controls['asset'].invalid && (addpropertyFormGroup.controls?.['asset']?.dirty || addpropertyFormGroup.controls['asset'].touched)">
                                <div *ngIf="addpropertyFormGroup.controls['asset'].errors?.['required']">
                                    <small class="text-danger">Select Assets Type</small>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="inputField__label">
                                Category  <span style="color:red;">*</span>
                            </div>
                            <select class="addProperty__dropdownOptions" formControlName="categoryList">
                                <option value="" selected disabled class="addProperty-dropdown-options"
                                    style="color: gray;">
                                    Select Category
                                </option>
                                <option *ngFor="let category of categoryList" [ngValue]="category?._id">
                                    {{category.name}}
                                </option>
                            </select>
                            <div class="errorText"
                                *ngIf="addpropertyFormGroup.controls['categoryList'].invalid && (addpropertyFormGroup.controls?.['categoryList']?.dirty || addpropertyFormGroup.controls['categoryList'].touched)">
                                <div *ngIf="addpropertyFormGroup.controls['categoryList'].errors?.['required']">
                                    <small class="text-danger">Select Category</small>
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="inputField__label">
                                Sub Category <span style="color:red;">*</span>
                            </div>
                            <select class="addProperty__dropdownOptions" formControlName="subcategory">
                                <option value="" selected disabled class="addProperty-dropdown-options"
                                    style="color: gray;">
                                    Select Sub Category
                                </option>
                                <option *ngFor="let subCategory of subCategoryList" [ngValue]="subCategory?._id">
                                    {{subCategory.name}}
                                </option>
                            </select>
                            <div class="errorText"
                                *ngIf="addpropertyFormGroup.controls['subcategory'].invalid && (addpropertyFormGroup.controls?.['subcategory']?.dirty || addpropertyFormGroup.controls['subcategory'].touched)">
                                <div *ngIf="addpropertyFormGroup.controls['subcategory'].errors?.['required']">
                                    <small class="text-danger">Select Category</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="property">
                        <div class="d-flex addproperty-blockone">
                            <div>
                                <app-input-filed label="Project Name" placeholder="Enter Project Name" [starIcon]="'*'"
                                    [control]="$any(addpropertyFormGroup).controls['projectName']"></app-input-filed>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['projectName'].invalid && (addpropertyFormGroup.controls?.['projectName']?.dirty || addpropertyFormGroup.controls['projectName'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['projectName'].errors?.['pattern']">
                                        <small class="text-danger">First letter must be alphabet</small>
                                    </div>
                                    <div *ngIf="addpropertyFormGroup.controls['projectName'].errors?.['required']">
                                        <small class="text-danger">Project Name is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="property-size">
                                <app-input-filed label="Property Size " placeholder="Enter Size" [starIcon]="'*'"
                                    [allowedType]="'number'"
                                    [control]="$any(addpropertyFormGroup).controls['propertySize']"></app-input-filed>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['propertySize'].invalid && (addpropertyFormGroup.controls?.['propertySize']?.dirty || addpropertyFormGroup.controls['propertySize'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['propertySize'].errors?.['pattern']">
                                        <small class="text-danger">In valid property size</small>
                                    </div>
                                    <div *ngIf="addpropertyFormGroup.controls['propertySize'].errors?.['required']">
                                        <small class="text-danger">Property Size is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="addproperty__dropdown">
                                <select class="addproperty__dropdownOptions" formControlName="unit">
                                    <option value="" selected disabled>Units</option>
                                    <option>sq. ft</option>
                                    <option>sq. yard</option>
                                </select>
                            </div>
                            <div class="property-price">
                                <app-input-filed label="Price {{selectedUnit}}"  placeholder="Enter Price" [allowedType]="'number'"
                                    [starIcon]="'*'" [control]="$any(addpropertyFormGroup).controls['pricePerUnit']"
                                    [maxDigits]="12"></app-input-filed>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['pricePerUnit'].invalid && (addpropertyFormGroup.controls?.['pricePerUnit']?.dirty || addpropertyFormGroup.controls['pricePerUnit'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['pricePerUnit'].errors?.['pattern']">
                                        <small class="text-danger">Price must be a valid number</small>
                                    </div>
                                    <div *ngIf="addpropertyFormGroup.controls['pricePerUnit'].errors?.['required']">
                                        <small class="text-danger">Price is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex   addproperty-blockone">
                            <app-input-filed label="Project By" placeholder="Project By"
                                [control]="$any(addpropertyFormGroup).controls['projectBy']"></app-input-filed>
                            <div>
                                <div class="inputField__label">
                                    Featured Property</div>
                                <select class="featured__dropdownOptions" formControlName="featuredProperty"
                                    (change)="onSelectChange($event)">
                                    <option [value]="" selected disabled class="addProperty-dropdown-options"
                                        style="color: gray;">Select
                                        project By</option>
                                    <option>No</option>
                                    <option>Yes</option>
                                </select>
                            </div>
                            <app-input-filed label="Featured property" placeholder="" style="visibility: hidden;"
                                [control]="$any(addpropertyFormGroup).controls['projectName']"></app-input-filed>
                        </div>
                        <div class="d-flex description-box">
                            <label>Description<span style="color:red;">*</span></label>
                            <textarea placeholder="Enter Description" appNoLeadingSpaces formControlName="description"></textarea>
                            <div class="errorText"
                                *ngIf="addpropertyFormGroup.controls['description'].invalid && (addpropertyFormGroup.controls?.['description']?.dirty || addpropertyFormGroup.controls['description'].touched)">
                                <div *ngIf="addpropertyFormGroup.controls['description'].errors?.['pattern']">
                                    <small class="text-danger">First letter must be alphabet</small>
                                </div>
                                <div *ngIf="addpropertyFormGroup.controls['description'].errors?.['required']">
                                    <small class="text-danger">Description is required</small>
                                </div>
                            </div>
                        </div>
                        
                        <div class="upload-img-block">
                            <label class="file-type-name">Media</label>
                            <div class="cursor-pointer" (click)="file.click()">
                                <input style="display: none" accept="{{'.jpg,.jpeg,.png,.PNG,.JPG,.JPEG'}}" #file
                                    type="file" (change)="uploadDoc($event)">
                                <div class="upload-icon text-secondary text-center">
                                    <div class="upload-icon-styles"> <img src="assets/images/upload-file-group.png">
                                    </div>
                                    <div class="upload-title">Upload Property Documents</div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start errormsg-block" *ngIf="fileUploadError">
                                <p class="text-danger">{{fileUploadError}}</p>
                            </div>
                        </div>
                        <div *ngIf="listOfFiles?.length" class="image-container">
                            <div *ngFor="let file of listOfFiles let index = index" class="image-item-block">
                                <img [src]="file.objectUrl" alt="Uploaded Image" class="uploaded-image">
                                <div (click)="removeSelectedFile(index)">
                                    <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip"
                                        ngbTooltip="Delete" [placement]="'top'"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="properet-address">Property Address</div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location">
                                    <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode"
                                        [showStar]="true" label="Enter Location"
                                        [labelClass]="'addProperty__dropdownLabel'" [className]="'addProperty-irr'">
                                    </AutocompleteComponent>
                                </div>
                                <div>
                                    <app-input-filed label="Line1" placeholder="Line1"
                                        [control]="$any(addpropertyFormGroup).controls['line1']"
                                        [starIcon]="'*'"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['line1'].invalid && (addpropertyFormGroup.controls?.['line1']?.dirty || addpropertyFormGroup.controls['line1'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid line1</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['required']">
                                            <small class="text-danger">Line1 is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <app-input-filed label="Line2" placeholder="Line2"
                                        [control]="$any(addpropertyFormGroup).controls['line2']"
                                        [starIcon]="'*'"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['line2'].invalid && (addpropertyFormGroup.controls?.['line2']?.dirty || addpropertyFormGroup.controls['line2'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line2'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid line2</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['line2'].errors?.['required']">
                                            <small class="text-danger">Line2 is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location">
                                    <app-input-filed label="Country" placeholder="Country"
                                        [control]="$any(addpropertyFormGroup).controls['country']"></app-input-filed>
                                </div>
                                <app-input-filed label="State" placeholder="State"
                                    [control]="$any(addpropertyFormGroup).controls['state']"></app-input-filed>
                                <app-input-filed label="City" placeholder="City"
                                    [control]="$any(addpropertyFormGroup).controls['city']"></app-input-filed>
                            </div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location">
                                    <app-input-filed label="Locality" [starIcon]="'*'" placeholder="Locality"
                                        [control]="$any(addpropertyFormGroup).controls['locality']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['locality'].invalid && (addpropertyFormGroup.controls?.['locality']?.dirty || addpropertyFormGroup.controls['locality'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid locality</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['locality'].errors?.['required']">
                                            <small class="text-danger">locality is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <app-input-filed label="Pincode" placeholder="Pincode" [starIcon]="'*'"
                                        [allowedType]="'number'" [maxDigits]="6"
                                        [control]="$any(addpropertyFormGroup).controls['pincode']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['pincode'].invalid && (addpropertyFormGroup.controls?.['pincode']?.dirty || addpropertyFormGroup.controls['pincode'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['pincode'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid pincode</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['pincode'].errors?.['required']">
                                            <small class="text-danger">Pincode is required</small>
                                        </div>
                                    </div>
                                </div>
                                <app-input-filed style="visibility: hidden;"></app-input-filed>
                            </div>
                        </div>
                        <div class="property-in-maps">
                            <img *ngIf="!addpropertyFormGroup.value?.location?.length"
                                src="assets/images/property-map.png">
                            <div class="map-div">
                                <google-map class="property-map" height="180px" width="73.61111111111111vw"
                                    [center]="center" [zoom]="zoom"
                                    *ngIf="addpropertyFormGroup.value?.location?.length">
                                    <map-marker [position]="positionData" [options]="markerOptions"></map-marker>
                                </google-map>
                            </div>
                        </div>
                        <div>
                            <div class="properet-address">Additional Features</div>
                            <div class="d-flex   addproperty-blockone">
                                <div class="add-property-location">
                                    <app-input-filed label="Max Rooms" placeholder="Max Rooms"
                                        [control]="$any(addpropertyFormGroup).controls['maxRooms']"
                                        [allowedType]="'number'" [maxDigits]="4"></app-input-filed>
                                </div>
                                <app-input-filed label="Beds" placeholder="Beds"
                                    [control]="$any(addpropertyFormGroup).controls['beds']" [allowedType]="'number'"
                                    [maxDigits]="4"></app-input-filed>
                                <app-input-filed label="Baths" placeholder="Baths"
                                    [control]="$any(addpropertyFormGroup).controls['baths']" [allowedType]="'number'"
                                    [maxDigits]="4"></app-input-filed>
                            </div>
                            <div class="d-flex additional-features-aminity ">
                                <div formArrayName="additionalFeaturesamenity" class="features-wrapper">
                                    <div *ngFor="let feature of additionalFeaturesamenityList; let i = index">
                                        <div class="form-check">
                                            <div class="col feature-item">
                                                <input type="checkbox" class="form-check-input" [formControlName]="i">
                                                <label class="form-check-label"
                                                    for="flexCheckDefault">{{feature}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex property-buttons-block btn-generate">
                        <app-common-button buttonClass="smallfilledbuttoncancel" buttonLabel="Cancel"
                            (clickEvent)="onClose()"></app-common-button>
                        <app-common-button buttonClass="smaiifilledbuttonsave" buttonLabel="Save"
                            [disabled]="addpropertyFormGroup.invalid" [loadingIcon]="verifyLoader"
                            (clickEvent)="generateProperty()"></app-common-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>