import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-common-icon',
  templateUrl: './common-icon.component.html',
  styleUrl: './common-icon.component.scss'
})
export class CommonIconComponent {

  @Input() iconSrc          !:string;
  @Input() loadingIcon      : boolean = false;
  @Input() className        !: string;
  @Input() toolTip          !: string;
  @Input() placement          !: string;
  @Input() faIcon           : IconDefinition | undefined;
  @Input() text             !: string;
  @Output() clickEvent      : EventEmitter<any> = new EventEmitter<void>();

  protected readonly faSpinner = faSpinner;

  onClickEvent(event: any) {
    if(!this.loadingIcon){
      this.clickEvent.emit();
    }
  }
}
