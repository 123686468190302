import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private http: HttpClient) { }

  private reloadSubModule : Subject<string> = new Subject();
  private reloadModule : Subject<string> = new Subject();


  public getSubModule() : Observable<string> {
    return this.reloadSubModule.asObservable();
  }
  public getModule() : Observable<string> {
    return this.reloadModule.asObservable();
  }

  public setModule(name : string) {
    this.reloadModule.next(name);
  }
  public setSubModule(name : string) {
    this.reloadSubModule.next(name);
  }

  getPropertyList(params:any,count: number): Observable<any> {
    let url = environment.API_URL + `/v1/property?skip=${count}`
    return this.http.get(url,{params})
  }

  getCategoryList(): Observable<any> {
    let url = `${environment.API_URL}/v1/category/list`
    return this.http.get(url)
  }

  getsubactegoryList(categoryId:any): Observable<any> {
    let url = `${environment.API_URL}/v1/category/subcategory?category_in=${categoryId}`
    return this.http.get(url)
  }

  getsubPropertyList(id : any) : Observable<any> {
    let url = `${environment.API_URL}/v1/property/${id}`
    return this.http.get(url)
  }

  getassetsList(): Observable<any> {
    let url = `${environment.API_URL}/v1/asset/list`
    return this.http.get(url)
  }

  generateProperty(propertyData: any): Observable<any> {
    let url = `${environment.API_URL}/v1/property/create`
    return this.http.post(url, propertyData);
  }

  uploadFiles(formData: FormData): Observable<any> {
    const url = `${environment.API_URL}/v1/file/upload`;
    return this.http.post(url, formData);
  }

  addAssets(name: any): Observable<any> {
    let url = `${environment.API_URL}/v1/asset`;
    return this.http.post(url,name);
  }

  editAssets(payload: any,_id: any): Observable<any> {
    let url = `${environment.API_URL}/v1/asset/${_id}`;
    return this.http.put(url,payload);
  }

  addModule(name: any): Observable<any> {
    name.status = "Active";
    let url = `${environment.API_URL}/v1/category`;
    return this.http.post(url,name);
  }

  editModule(payload: any,_id: any): Observable<any> {
    let url = `${environment.API_URL}/v1/category/${_id}`;
    return this.http.put(url,payload);
  }

  addSubModule(name: any,_id: any): Observable<any> {
    let url = `${environment.API_URL}/v1/category/${_id}/subcategory`;
    return this.http.post(url,name);
  }

  editSubModule(payload: any,_id: any): Observable<any> {
    let url = `${environment.API_URL}/v1/category/subcategory/${_id}`;
    return this.http.put(url,payload);
  }

  addPropertyEnquiry(name: any,_id: any):Observable<any> {
    let url =`${environment.API_URL}/v1/property-Enq/enquiry/${_id}`;
    return this.http.post(url,name);
  }

   //get user details
   getuserDetails(): Observable<any> {
    let url = `${environment.API_URL}/v1/oauth/self`
    return this.http.get(url)
  }

//on edot save profile
  saveProfile(userData:any){
    let url = `${environment.API_URL}/v1/oauth/edit`
    return this.http.put(url, userData);
  }

  getEnquiryList(_id: any):Observable<any> {
    let url = `${environment.API_URL}/v1/property-Enq/${_id}`;
    return this.http.get(url);
  }

  featuredProperties():Observable<any> {
    let url = `${environment.API_URL}/v1/property?featured=true`;
    return this.http.get(url);
  }
  addContactusDetails(name: any): Observable<any> {
    let url = `${environment.API_URL}/v1/contact-us/request`;
    return this.http.post(url,name);
  }

  //get amenities
  getAmenitiesList(): Observable<any> {
    let url = `${environment.API_URL}/v1/amenity`
    return this.http.get(url)
  }



  getOverallEnquiryList(): Observable<any> {
    let url = `${environment.API_URL}/v1/property-enq/enquiry-list/list`
    return this.http.get(url)
  }

}
