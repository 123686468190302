import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterObjectSource = new BehaviorSubject<any>(null);
  currentFilterObject = this.filterObjectSource.asObservable();
  constructor() { }

  updateFilterObject(filterObject: any): void {
    this.filterObjectSource.next(filterObject);
  }
}
