import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appNoLeadingSpaces]'
  })
  export class NoLeadingSpacesDirective {
  
    constructor(private el: ElementRef) { }
  
    @HostListener('input', ['$event'])
    onInput(event: Event): void {
      const input = this.el.nativeElement as HTMLInputElement;
      const trimmedValue = input.value.replace(/^\s+/, '');
      if (input.value !== trimmedValue) {
        input.value = trimmedValue;
        input.dispatchEvent(new Event('input'));
      }
    }
  }