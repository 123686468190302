<div class="customer-reviews-mainblock">
    <div class="row investors-note-block">
        <div class="col investors-note">What’s our Investors Says</div>
        <div class="col investors-note-field">
            <hr>
        </div>
    </div>
    <div class="carousel-block">
        <img class="image-vectors" src="assets/images/Vector.png">
        <ngb-carousel #carousel [interval]="2000" [showNavigationIndicators]="true" [showNavigationArrows]="true">
            <ng-template *ngFor="let review of reviews" ngbSlide>
                <div class="row carousel-content">
                    <div class="col col-md-6 carousel-title">
                        <h3>{{ review.title }}</h3>
                        <p>{{ review.description }}</p>
                    </div>
                    <div class="col co-md-3 carousel-img-block">
                        <img src="assets/images/investors.png" alt="img">
                    </div>
                    <div class="col col-md-3 carousel-name">
                        <h3>{{ review.name }}</h3>
                        <p>{{ review.designation }}</p>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>

</div>