<div class="dashboard-card">
    <div class="dashboard-heading-div">
        <p class="dashboard-heading">Category({{ moduleDetails?.length || 0 }})</p>
    </div>
    <div class="container">
        <div class="row">
            <div *ngFor="let category of moduleDetails" class="col-md-3 col-sm-6">
                <div class="counter">
                    <div class="counter-content" [ngStyle]="{'background': category?.color}">
                        <h3>{{ category.name }}</h3>
                    </div>
                    <span id="{{ category._id }}" class="counter-value"
                        [ngStyle]="{'background': category.lighterColor}">
                        <app-animated-count [digit]="category?.count || 0" [duration]="300"
                            [steps]="3"></app-animated-count>
                        <span class="after-element"
                            [ngStyle]="{'background': 'linear-gradient(to top left, transparent 49%, ' + category.color + ' 50%)'}"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <div class="card enquiry">
            <div class="card-body">
                <div class="enquiry-header">
                    <h5 class="card-title">Enquiries</h5>
                    <div class="dashboard__dropdown">
                        <select #rangeDate class="dashboard__dropdownOptions propet-s custom-dropdown-arrow"
                            (change)="handleSelection(rangeDate.value)">
                            <option value="1">Today</option>
                            <option value="7">7 days</option>
                            <option value="15">15 days</option>
                            <option value="30">30 days</option>
                            <option value="custom">Custom Range</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="selectedDateRange" class="selected-date-range" [ngClass]="{'show': dateRangeOpen}">
                    <p>{{ selectedDateRange.fromDate | date:"dd MMM yy"}} - {{ selectedDateRange.toDate | date:"dd MMM yy" }}</p>
                </div>
                <p class="card-text">{{ filterEnquiryDate?.totalenq }}</p>
            </div>
        </div>
    </div>

    <div class="col-md-3 m-5">
        <div class="card-counter info">
            <fa-icon [icon]="faUsers" class="icon"></fa-icon>
            <span class="count-numbers">{{ userCount?.data }}</span>
            <span class="count-name">Users</span>
        </div>
    </div>
</div>

<app-dashboard-popup *ngIf="dateRangeOpen" [dateRange]="dateRangeOpen" (closeModal)="closeDatePicker()"
    (dateRangeSelected)="applyFilter($event)"></app-dashboard-popup>