import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { DashboardCountService } from '../../services/dashboard/dashboard-count.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {

  moduleDetails     : any;
  categoriesCount   : any;
  propertiesCount   : any;
  userCount         : any;
  filterEnquiryDate : any;
  dateRangeOpen     : boolean = false;
  selectedDateRange : { fromDate: string, toDate: string } | null = null;
  protected faUsers = faUsers;

  colors: string[] = [
    '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#FF8C33', '#33FF8C', '#8C33FF', '#33A1FF', '#FF3380'
  ];

  constructor(
    private storageService: StorageService,
    private dashboardService: DashboardCountService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.moduleDetails = this.storageService.getDataFromLocalStorage(StorageKeys.MODULENAME);
    this.moduleDetails.forEach((category: any, index: any) => {
      const color = this.colors[index % this.colors.length];
      category.color = color;
      category.lighterColor = this.lightenColor(color, 20);
    });
    this.categoryCount();
    this.usersCount();
    this.handleSelection(1);
  }

  lightenColor(color: string, percent: number): string {
    const num = parseInt(color.slice(1), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = (num >> 8 & 0x00FF) + amt;
    const B = (num & 0x0000FF) + amt;
    return '#' + (
      0x1000000 +
      (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 +
      (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 +
      (B < 255 ? B < 1 ? 0 : B : 255)
    ).toString(16).slice(1).toUpperCase();
  }

  categoryCount(): void {
    this.dashboardService.categoryDashboardCount()?.subscribe({
      next: (res: any) => {
        this.categoriesCount = res.data.result;
        this.matchCategoryCounts();
      },
      error: (error: any) => {
        console.error('Error fetching category counts:', error);
      }
    });
  }

  matchCategoryCounts(): void {
    if (!this.moduleDetails || !this.categoriesCount) {
      return;
    }
    this.moduleDetails.forEach((module: any) => {
      const matchingCategory = this.categoriesCount.find((count: any) => count.category === module._id);
      module.count = matchingCategory ? matchingCategory.count : 0;
    });
  }

  usersCount() {
    this.dashboardService.usersCount()?.subscribe({
      next: (res: any) => {
        this.userCount = res;
      },
      error: (error: any) => {
        console.error('Error fetching user counts:', error);
      }
    });
  }

  handleSelection(value: any) {
    const selectedOption: any = value;
    let fromDate = new Date();
    let toDate = new Date();

    if (selectedOption == 1) {
      fromDate.setDate(fromDate.getDate() - Math.abs(selectedOption - 1));
    } else if (selectedOption !== 'custom') {
      fromDate.setDate(fromDate.getDate() - Math.abs(selectedOption));
      toDate.setDate(toDate.getDate() - 1); 
      this.selectedDateRange = null; 
      this.closeDatePicker();
    } else {
      this.openDatePicker();
      return;
    }

    let range = {
      created_datefrom: this.datePipe.transform(fromDate, 'yyyy-MM-dd'),
      created_dateupto: this.datePipe.transform(toDate, 'yyyy-MM-dd')
    };

    this.getDashboardFilterEnquiry(range);
}


  openDatePicker() {
    this.dateRangeOpen = true;
  }

  closeDatePicker() {
    this.dateRangeOpen = false;
  }

  applyFilter(event: any) {
    const fromDate = new Date(event.fromDate.year, event.fromDate.month - 1, event.fromDate.day);
    const toDate = new Date(event.toDate.year, event.toDate.month - 1, event.toDate.day);
    let range = {
      created_datefrom: this.datePipe.transform(fromDate, 'yyyy-MM-dd') ?? '',
      created_dateupto: this.datePipe.transform(toDate, 'yyyy-MM-dd') ?? ''
    };
    this.selectedDateRange = { fromDate: range.created_datefrom, toDate: range.created_dateupto };
    this.getDashboardFilterEnquiry(range);
    this.closeDatePicker();
  }

  getDashboardFilterEnquiry(dateRange: any) {
    this.dashboardService.getDashboardFilterEnquiry(dateRange)?.subscribe({
      next: (res: any) => {
        this.filterEnquiryDate = res.data;
      },
      error: (error: any) => {
        console.error('Error fetching property counts:', error);
      }
    });
  }
}
