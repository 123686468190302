<div class="sub-height" *ngIf="subModuleList && subModuleList?.length > 0">
    <table class="custom-table-list submodule " aria-label="cred_table">
        <thead>
            <tr class="custom-table-list-headings">
                <th class="custom-col-width-30">Name</th>
                <th class="custom-col-width-30">Created on</th>
                <th class="custom-col-width-30">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr class="custom-table-list-row-main" *ngFor="let submodule of subModuleList">
                <td class="custom-col-width-30">{{submodule.name}}</td>
                <td class="custom-col-width-30">{{submodule.created | date : "MMM d, y, h:mm:ss a"}}</td>
                <td class="custom-col-width-30">
                    <span (click)="openOverlay('edit',submodule)" data-bs-toggle="tooltip" ngbTooltip="Edit"
                        [placement]="'left'">
                        <div class="edit">
                            <img src="../../../assets/images/edit.png">
                        </div>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="loader">
    <div class="custom-col-width-100 d-flex justify-content-center" style="color: #4682B4;">
        <app-common-icon [loadingIcon]="loader"></app-common-icon>
    </div>
</div>
<div *ngIf="!loader && subModuleList?.length === 0">
    <div class="pt-3 custom-col-width-100 text-danger d-flex justify-content-center">
        No Sub module Found!
    </div>
</div>