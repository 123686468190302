import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';

@Component({
  selector: 'app-enquiry-popup',
  templateUrl: './enquiry-popup.component.html',
  styleUrl: './enquiry-popup.component.scss'
})
export class EnquiryPopupComponent implements OnInit {
  enquiryForm       !: FormGroup;
  errorMessage       : string = '';
  originalPhoneNumber: string = ''; 
  number             : any;
  verifyLoader       : boolean = false;
  loader             : boolean = false;
  @Input() isEnquiry : boolean = false;
  @Input() propertyId: any;  
  @Output()closeModal: EventEmitter<void> = new EventEmitter<void>();


  constructor(private fb: FormBuilder,
              private propertyService: PropertyService,
              private storageService: StorageService,
  )
  { 
    this.generateEnquiryForm();
  }

  ngOnInit(): void {
    let mobileNumber = this.storageService?.getDataFromLocalStorage(
      StorageKeys.PHONE_NUMBER
    )
    if (mobileNumber) {
      this.number = mobileNumber;
      this.originalPhoneNumber = mobileNumber; 
      this.patchDetails();
    }
  }

  close() {
    this.closeModal.emit();
   this.enquiryForm.get('message')?.reset();
   this.enquiryForm.get('phoneNumber')?.setValue(this.originalPhoneNumber);
  }

  generateEnquiryForm() {
    this.enquiryForm = this.fb.group({
      phoneNumber: ['',[Validators.required,Validators.pattern(RegExpPatterns.MobilePattern)]],
      message: ['', [Validators.required]]
    });
  }

  addEnquiryList() {
    this.propertyService.addPropertyEnquiry({
      ...this.enquiryForm.value
    },this.propertyId).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.close();
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  onSubmit() {
    if(this.enquiryForm.valid){
      this.loader = true;
      this.addEnquiryList();
    }
  }

  patchDetails() {
    if (this.number) {
      this.enquiryForm?.patchValue({
        phoneNumber:this.number
      });
    }
  }

}
