<div>
    <div class="offCanvas__container">
        <div class="offCanvas__contentWrapper">
            <div class="offCanvas__header">
                <div class="offCanvas__title">
                    {{ 'Edit Profile' }}
                </div>
                <img (click)="onCloseIconClick()" class="offCanvas__crossIcon" src="../../../../assets/images/Cross.svg"
                    alt="cross icon" />
            </div>
        </div>
    </div>

    <section class="userDetails__container">
        <div class="userDetails__details mt-4">
            <div>
                <div class="userDetails__imageContainer">
                    <div class="profile-image">
                        <ng-container class="profile-image-block"
                            *ngIf="userDetailsData?.profileImage || profileImage ">
                            <img [src]="profileImage || userDetailsData?.profileImage" alt="Image" />
                            <span class="profile-image-user"> <fa-icon [icon]="faEdit"  (click)="file.click()"></fa-icon></span>
                        </ng-container>
                        <ng-container class="profile-image-block" *ngIf="!userDetailsData?.profileImage && !profileImage">
                            <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 160px; margin-right: 50px; ">
                            </fa-icon>
                            <span class="profile-image-default-user"> <fa-icon [icon]="faEdit"  (click)="file.click()"></fa-icon></span>
                        </ng-container>
                    </div>
                    <div class="profile-change">
                        <input style="display: none" #file type="file" accept="image/*,jpg,png"
                            (change)="upload($event)" />
                    </div>
                </div>
            </div>
            <div class="userDetails__infobox">
                <div class="userDetails__data">
                    <div class="mdc-card">
                        <form [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                            <div class=" p-4 column mdc-card-name">
                                <div class="col-md-6" style="margin-bottom: 20px">
                                    <label>Your Name<span style="color: red;">*</span></label>
                                    <input class="userDetails__infobox_input" placeholder="Name" input
                                        formControlName="name" [disabled]="isNameDisabled" appNoLeadingSpaces
                                        type="text" required maxlength="50" />
                                </div>
                                <div class="col-md-6" style="margin-bottom: 20px">
                                    <label> Email</label>
                                    <input class="userDetails__infobox_input" placeholder="Email" input
                                        formControlName="email"  
                                        type="text" required maxlength="50" />
                                </div>
                                <div class="errorText"
                                    *ngIf="profileForm.controls['name'].invalid && (profileForm.controls?.['name']?.dirty || profileForm.controls['name'].touched)">
                                    <div *ngIf="profileForm.controls['name'].errors?.['pattern']">
                                        <small class="text-danger">First letter must be alphabet</small>
                                    </div>
                                    <div *ngIf="profileForm.controls['name'].errors?.['required']">
                                        <small class="text-danger"> Name is required</small>
                                    </div>
                                </div>
                                <div class="errorText"
                                    *ngIf="profileForm.controls['email'].invalid && (profileForm.controls?.['email']?.dirty || profileForm.controls['email'].touched)">
                                    <div *ngIf="profileForm.controls['email'].errors?.['pattern']">
                                        <small class="text-danger">Enter Valid Email Address</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row offCanvas__footer">
                                <div class="col-md-4"></div>
                                <div class="btn-generate col-md-8">
                                    <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Cancel"
                                        (clickEvent)="onCloseIconClick()"></app-common-button>
                                    <app-common-button buttonClass="view-enquiry" [disabled]="profileForm.controls['name'].invalid"
                                        buttonLabel="Submit" [loadingIcon]="verifyLoader"></app-common-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>