import { ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '../../services/property/property.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { RegExpPatterns } from '../utilites/regex-pattern';

@Component({
  selector: 'app-add-properties',
  templateUrl: './add-properties.component.html',
  styleUrl: './add-properties.component.scss'
})
export class AddPropertiesComponent {
  center: google.maps.LatLngLiteral = {
    lat: 16.5061743, 
    lng: 80.6480153
  };
  positionData: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };
  display: any;
  zoom = 18;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  addpropertyFormGroup !: FormGroup;
  assetsList: any;
  categoryList: any;
  subCategoryList: any;
  categoryId: any;
  additionalFeaturesamenityList: any;
  listOfFiles: any[] = [];
  uploadfileList: any[] = [];
  fileList: File[] = [];
  fileUploadError: string = '';
  faTrash = faTrash;
  verifyLoader: boolean = false;
  amenities: any;
  amenitiesList: any;
  selectedUnit: string = '';
  constructor(
    private _fb: FormBuilder,
    private propertyService: PropertyService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.createContractForm();
    this.getassetsList();
    this.getAmenitiesList();
    this.getCategoryList();

    this.addpropertyFormGroup.get('categoryList')?.valueChanges.subscribe((_id) => {
      if (_id) {
        this.getsubactegoryList(_id);
        this.categoryId = _id
      }
    });
    this.addpropertyFormGroup.get('location')?.valueChanges.subscribe((location) => {
      if (location && location.length === 2) {
        this.updateLocation(location);
      } else {
        this.clearLocation();
      }
    });
    this.addpropertyFormGroup.get('unit')?.valueChanges.subscribe((unit: string) => {
      this.selectedUnit = "per "+ unit;
    });
  }


  createContractForm() {

    this.additionalFeaturesamenityList = [
      'Emergency Exit',
      'CCTV',
      'Free Parking In The Area',
      'Security Guard',
      'Air Conditioning',
      'Terrace',
      'Elevator Lift'
    ];
    this.addpropertyFormGroup = this._fb.group({
      asset: new FormControl('', [Validators.required]),
      categoryList: new FormControl('', [Validators.required]),
      subcategory: new FormControl('', [Validators.required]),
      projectName: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.name)]),
      propertySize: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.propertySize)]),
      pricePerUnit: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.price)]),
      description: new FormControl('', [Validators.required]),
      projectBy: new FormControl(''),
      featuredProperty: new FormControl('No'),
      line1: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.address)]),
      line2: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.address)]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      location:new FormControl([], [Validators.required]),
      unit: new FormControl('', [Validators.required]),

      //additional info
      maxRooms: new FormControl(''),
      beds: new FormControl(''),
      baths: new FormControl(''),
      additionalFeaturesamenity: this._fb.array(this.additionalFeaturesamenityList.map(() => new FormControl(false)))
    });
  }

  get additionalFeatures() {
    return this.addpropertyFormGroup.get('additionalFeaturesamenity') as FormArray;
  }

  onSelectChange(event: any) {
    const selectedValue = event.target.value;
      this.addpropertyFormGroup.patchValue({
        featuredProperty: selectedValue
      });
  }

  //get assets
  getassetsList() {
    this.propertyService.getassetsList()?.subscribe({
      next: (res: any) => {
        this.assetsList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }


  //get getCategoryList
  getCategoryList() {
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.categoryList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  //get subactegoryList
  getsubactegoryList(categoryId: string) {
    this.propertyService.getsubactegoryList(categoryId)?.subscribe({
      next: (res: any) => {
        this.subCategoryList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  //getting amenities
  getAmenitiesList() {
    this.propertyService.getAmenitiesList()?.subscribe({
      next: (res: any) => {
        this.amenitiesList = res?.data;
        this.amenities = this.amenitiesList.map((item:any) => item.name);
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }  

  //file upload
  uploadDoc(event: any) {
    const formData = new FormData();
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/PNG', 'image/JPG'];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      if (allowedFileTypes.indexOf(selectedFile.type) === -1) {
        this.fileUploadError = 'Only jpg/png/jpeg format allowed';
        return;
      }

      formData.append('file', selectedFile);

      this.propertyService.uploadFiles(formData)?.subscribe({
        next: (res: any) => {
          this.uploadfileList.push(res?.data?._id);
          this.listOfFiles.push(res.data);
        },
        error: (error: any) => {
          console.error(error);
        }
      });
    }
  }

  //deleting the file
  removeSelectedFile(index: number): void {
    this.listOfFiles.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  formatAddress($event: any) {
    if ($event) {
      this.handlePropertyAddress($event);
    } else {
      this.clearAddress();
    }
  }

  handlePropertyAddress(address: any) {
    this.addpropertyFormGroup.patchValue({ ...address });
    this.markFormGroupTouched(this.addpropertyFormGroup);
    this.addpropertyFormGroup.updateValueAndValidity();
    this.cdr.detectChanges();
  }


  clearAddress() {
    this.addpropertyFormGroup.patchValue({
      line1: '',
      line2: '',
      country: '',
      state: '',
      city: '',
      locality: '',
      pincode: '',
      location: ''
    });
    this.markFormGroupTouched(this.addpropertyFormGroup);
    this.addpropertyFormGroup.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onClose() {
    this.router.navigate(['/properties-list'])
    this.addpropertyFormGroup.reset();
  }


  generateProperty() {
    this.verifyLoader = true;
    const selectedFeatures = this.additionalFeatures.value
      .map((isSelected: boolean, index: number) => isSelected ? this.additionalFeaturesamenityList[index] : null)
      .filter((feature: string | null) => feature !== null);
    const formValues = this.addpropertyFormGroup.value;
    const propertyData = {
      ...formValues,
      documents: this.uploadfileList,
      address: {
        line1: this.addpropertyFormGroup.value.line1,
        line2: this.addpropertyFormGroup.value.line2,
        country: this.addpropertyFormGroup.value.country,
        state: this.addpropertyFormGroup.value.state,
        city: this.addpropertyFormGroup.value.city,
        locality: this.addpropertyFormGroup.value.locality,
        pincode: this.addpropertyFormGroup.value.pincode,
        location: this.addpropertyFormGroup.value.location
      },
      advanceFeatures: {
        maxRooms: this.addpropertyFormGroup.value.maxRooms,
        beds: this.addpropertyFormGroup.value.beds,
        baths: this.addpropertyFormGroup.value.baths,
        amenity: selectedFeatures
      },
      propertySize: {
        value: this.addpropertyFormGroup.value.propertySize,
        unit : this.addpropertyFormGroup.value.unit
      }
    };
    if (this.addpropertyFormGroup.value.location && this.addpropertyFormGroup.value.location.length === 2) {
      this.positionData = {
        lat: this.addpropertyFormGroup.value.location[0],
        lng: this.addpropertyFormGroup.value.location[1]
      };
    }
    propertyData.documents = this.uploadfileList;
    this.propertyService.generateProperty(propertyData).subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.verifyLoader = false;
          this.router.navigate(['/properties-list'])
        }
      },
      error: (error: any) => {
        this.verifyLoader = true;
        console.error(error);
      }
    });
  }

  updateLocation(location: number[]) {
    this.center = {
      lat: location[0],
      lng: location[1]
    };
    this.positionData = {
      lat: location[0],
      lng: location[1]
    };
  }
 
  clearLocation() {  
    this.positionData = {
      lat: 0,
      lng: 0
    };
    this.center = {
      lat: 16.5061743,
      lng: 80.6480153
    };
    this.cdr.detectChanges();
  }
}
