<section class="section1" id="section1">

    <div class="container">

        <!-- SECTION HEADING -->

        <h2 class="h2">Privacy Policy &amp; Terms of Use</h2>

        <div class="sub-heading">
            Effective 1st April 2024
        </div>


        <div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <p class="text-justify p f-24">
                            We recognize the importance of privacy as well as the
                            importance of maintaining the confidentiality of
                            personal information and this privacy policy describes
                            how REALT BOX INDIA PRIVATE LIMITED (hereinafter
                            referred to as “REALT BOX”) collects, uses and
                            discloses personal information of customers through
                            this www.realtbox.in & Mobile applications. REALT BOX
                            is sensitive to privacy issues on the Internet. We
                            believe it is important you know how we treat the
                            information we receive from you, on the Internet. This
                            Policy does not apply to websites and services that
                            display or link to different privacy statements.
                        </p>
                        <p class="text-justify p f-24">
                            <strong>Note:</strong>&nbsp;Our privacy Policy may change at any time
                            without prior notification. To make sure that you are
                            aware of any changes, kindly review the Policy
                            periodically. This Policy shall apply uniformly through
                            this Site
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Terms of Use</h4>
                        <p class="text-justify  p f-24">
                            The use of any product, service or feature (the
                            "Materials") available through the internet websites
                            accessible at www.realtbox.in and Mobile applications
                            (collectively, the "Website") by any user of the Website
                            ("You" or "Your" hereafter) shall be governed by the
                            following terms of use:
                        </p>
                        <ul class="text-justify card-ul text-col">
                            <li>
                                This Website is provided by REALT BOX INDIA PRIVATE
                                LIMITED (hereinafter referred to as “REALT BOX”), a
                                company incorporated under the Companies Act, 2013 of
                                India, having its registered office at VILLA NO 40, DIVINO
                                VILLAS, TELLAPUR, HYDERABAD, Telangana - 500019,
                                India and shall be used for informational purposes only.
                                By using the Website or downloading Materials from the Website, You hereby agree to
                                abide by the terms and
                                conditions set forth in this Terms of Use. In the event of
                                You not agreeing to these terms and conditions, You are
                                requested by REALT BOX not to use the Website or
                                download Materials from the Website.`
                            </li>
                            <li>
                                This Website, including all Materials present (excluding
                                any applicable third party materials), is the property of
                                REALT BOX and is copyrighted and protected by
                                worldwide copyright laws and treaty provisions. You
                                hereby agree to comply with all copyright laws worldwide
                                in Your use of this Website and to prevent any
                                unauthorized copying of the Materials. REALT BOX does
                                not grant any express or implied rights under any
                                patents, trademarks, copyrights or trade secret
                                information.
                            </li>
                            <li>
                                REALT BOX has business relationships with different
                                types of customers, suppliers, governments, and others.
                                For convenience and simplicity, words like joint venture,
                                partnership, and partner are used to indicate business
                                relationships involving common activities and interests,
                                and those words may not indicate precise legal
                                relationships.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Third Party Content</h4>
                        <p class="text-justify  p f-24">
                            The Website makes information of third parties
                            available, including articles, analyst reports, news
                            reports, tools to facilitate calculation, company
                            information and data about financial markets,
                            including any regulatory authority and other financial
                            markets and other data from external sources (the
                            "Third Party Content"). You acknowledge and agree
                            that the Third Party Content is not created or endorsed
                            by REALT BOX. The provision of Third Party Content is
                            for general informational purposes only and does not
                            constitute a recommendation or solicitation to
                            purchase or sell any securities or shares or to make any
                            other type of investment or investment decision. In
                            addition, the Third Party Content is not intended to provide tax, legal or investment
                            advice. You
                            acknowledge that the Third Party Content provided to
                            You is obtained from sources believed to be reliable,
                            but that no guarantees are made by REALT BOX or the
                            providers of the Third Party Content as to its accuracy,
                            completeness, timeliness
                        </p>
                        <p class="text-justify  p f-24">
                            By using any Third Party Content, You may leave this
                            Website or Mobile application and be directed to an
                            external website, or to a website maintained by an
                            entity other than REALT BOX. If You decide to visit any
                            such site, You do so at Your own risk and it is Your
                            responsibility to take all protective measures to guard
                            against viruses or any other destructive elements.
                            REALT BOX makes no warranty or representation
                            regarding, and does not endorse, any linked Websites
                            or the information appearing thereon or any of the
                            products or services described thereon. Links do not
                            imply that REALT BOX or this Website sponsors,
                            endorses, is affiliated or associated with, or is legally
                            authorized to use any trademark, trade name, logo or
                            copyright symbol displayed in or accessible through
                            the links, or that any linked site is authorized to use any
                            trademark, trade name, logo or copyright symbol of
                            REALT BOX or any of its affiliates or subsidiaries
                        </p>
                        <p class="text-justify  p f-24">
                            You hereby expressly acknowledge and agree that the
                            linked sites are not under the control of REALT BOX and
                            REALT BOX is not responsible for the contents of any
                            linked site or any link contained in a linked site, or any
                            changes or updates to such sites. REALT BOX is not
                            responsible for webcasting or any other form of
                            transmission received from any linked site.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">No Warranties</h4>
                        <p class="text-justify  p f-24">
                            This website, the information and materials on the site,
                            and any software made available on the Website, are
                            provided "as is" without any representation or
                            warranty, express or implied, of any kind, including,
                            but not limited to, warranties of merchantability, non-
                            infringement, or fitness for any particular purpose.
                            There is no warranty of any kind, express or implied,
                            regarding third party content. In spite of REALT BOX
                            best endeavors, there is no warranty on behalf of
                            REALT BOX that this Website will be free of any
                            computer viruses.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Consent</h4>
                        <p class="text-justify  p f-24">
                            By availing our Services and/or by providing your
                            information, you hereby consent to the collection,
                            receiving, possessing, storing, dealing, sharing and/or
                            handling of your personal information by the REALT
                            BOX INDIA PRIVATE LIMITED (known as REALT BOX) in
                            the manner and to the extent provided under this
                            Policy as may be modified by the REALT BOX INDIA
                            PRIVATE LIMITED (known as REALT BOX) from time to
                            time.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Security</h4>
                        <p class="text-justify  p f-24">
                            We have reasonable security measures and
                            procedures, and as specified by applicable law, to
                            maintain appropriate physical, technical and
                            administrative security to help prevent loss, misuse, or
                            unauthorized access, disclosure or modification of
                            sensitive personal information. While we take these
                            reasonable efforts to safeguard your personal
                            information, you acknowledge and agree that no
                            system or transmission of data over the Internet or any
                            other public network can be guaranteed to be 100%
                            secure.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Disclaimer</h4>
                        <p class="text-justify  p f-24">
                            The website may contain inaccuracies and
                            typographical and clerical errors. REALT BOX expressly
                            disclaims any obligation(s) to update this website or
                            any of the materials on this website. REALT BOX does
                            not warrant the accuracy or completeness of the
                            materials or the reliability of any advice, opinion,
                            statement or other information displayed or distributed
                            through the Website. You acknowledge that any
                            reliance on any such opinion, advice, statement,
                            memorandum, or information shall be at your sole risk.
                            REALT BOX reserves the right, in its sole discretion, to
                            correct any errors or omissions in any portion of the
                            Website. REALT BOX may make any other changes to
                            the Website, the materials and the products,
                            programs, services or prices (if any) described in the
                            Website at any time without notice. This Website is for
                            informational purposes only and should not be
                            construed as technical advice of any manner.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Lawful and/or prohibited use</h4>
                        <p class="text-justify  p f-24">
                            As a condition of Your use of the Website, You shall not
                            use the Website for any purpose(s) that is unlawful or
                            prohibited by the Terms of Use. You shall not use the
                            Website in any manner that could damage, disable,
                            overburden, or impair any REALT BOX server(s), or the
                            network(s) connected to any REALT BOX server, or
                            interfere with any other party's use and enjoyment of
                            any services associated with the Website. You shall not
                            attempt to gain unauthorized access to any section of
                            the Website, other accounts, computer systems or
                            networks connected to any REALT BOX server or to any
                            of the services associated with the Website, through
                            hacking, password mining or any other means. You
                            shall not obtain or attempt to obtain any Materials or information through any means not
                            intentionally made
                            available through the Website.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Indemnity</h4>
                        <p class="text-justify  p f-24">
                            You agree to indemnify and hold harmless REALT BOX,
                            its subsidiaries, its investors, their directors, officers,
                            employees, shareholders, partners, agents and
                            affiliates from any claim, cost, expense, judgment or
                            other loss relating to Your use of this Website in any
                            manner, including without limitation of the foregoing,
                            any action You take which is in violation of the terms
                            and conditions of these Terms of Use and against any
                            applicable law.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Changes</h4>
                        <p class="text-justify  p f-24">
                            REALT BOX reserves the rights, at its sole discretion, to
                            change, modify, add or remove any portion of these
                            Terms of Use in whole or in part, at any time. Changes
                            in these Terms of Use will be effective without notice of
                            such changes. Your continued use of the Website after
                            any changes to these Terms of Use are posted will be
                            considered acceptance of those changes. REALT BOX
                            may terminate, change, suspend or discontinue any
                            aspect of the Website, including the availability of any
                            feature(s) of the Website, at any time. REALT BOX may
                            also impose limits on certain features and services or
                            restrict Your access to certain sections or all of the
                            Website without notice or liability. Once posted on the
                            Site, the new Privacy Policy will be effective
                            immediately. You agree that any information in our
                            possession (as collected in accordance with this Policy,
                            whether prior to or after the new Privacy Policy becoming effective) will be governed by the
                            latest
                            version of our Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Governing Law</h4>
                        <p class="text-justify  p f-24">
                            This Site is controlled, operated and administered by
                            REALT BOX from its offices within India. REALT BOX
                            makes no representation that Materials on this Website
                            are appropriate or available for use at any other
                            location(s) outside India. This Privacy Policy and Terms
                            of Use shall be governed by the laws of India and the
                            courts at Hyderabad shall have exclusive jurisdiction in
                            respect of any dispute arising from the Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="feature">
                        <h4 class="para-heading">Feedback</h4>
                        <p class="text-justify  p f-24">
                            We welcome your input regarding our Policy and any
                            comments on the Services we provide to you. You may
                            send us your comments and responses by post to
                            REALT BOX INDIA PRIVATE LIMITED
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- /END CONTAINER -->

</section>
<app-footer></app-footer>
