<div class="modal" tabindex="-1" role="dialog" [class.show]="isVisible">
    <div class="modal-dialog custom-modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header modal-header_head">
          <h5 class="modal-title">Log In</h5>
          <img class="img-move" src="../../assets/images/icons8-cancel.svg" (click)="close()">
        </div>
        <div class="modal-body">
          <p>Please login to continue..</p>
          <div class="button-container">
            <app-common-button buttonLabel="Cancel" buttonClass="smallbuttoncancel" (click)="close()"></app-common-button>
            <app-common-button buttonLabel="Login" buttonClass="smallButton" [loadingIcon]="verifyLoader" (click)="Login()"></app-common-button>
          </div>
          <form *ngIf="showForm2" [formGroup]="signupForm">
            <app-input-filed label="MobileNumber" placeholder="Number" [allowedType]="'number'"
                [control]="$any(signupForm).controls['phoneNumber']" [starIcon]="'*'" [maxDigits]="10"
                [disabled]="$any(signupForm).controls['phoneNumber'].disable()">
            </app-input-filed>
            <app-input-filed label="OTP" placeholder="Enter otp" [allowedType]="'number'"
                [control]="$any(signupForm).controls['otp']" [starIcon]="'*'" [maxDigits]="6"></app-input-filed>
            <div *ngIf="errorMessage">
              <small class="text-center text-danger">{{ errorMessage }}</small>
            </div>
            <app-input-filed label="Name" placeholder="Name" [allowedType]="'name'"
                [control]="$any(signupForm).controls['name']" [starIcon]="'*'"></app-input-filed>
            <div class="accountLogin">
              <a style="text-decoration: none;" class="text-link">
                <div class="resendText">Resend OTP</div>
              </a>
            </div>
            <div class="move-button">
              <app-common-button buttonClass="smallButton" buttonLabel="Submit Otp" [loadingIcon]="verifyLoader"
                [disabled]="signupForm.invalid" (click)="submitOtp()"></app-common-button>
            </div>
          </form>
          <form *ngIf="showForm3" [formGroup]="createForm">
            <app-input-filed label="MobileNumber" placeholder="Number" [allowedType]="'number'"
                [control]="$any(createForm).controls['phoneNumber']" [starIcon]="'*'" [maxDigits]="10"
                [disabled]="$any(createForm).controls['phoneNumber'].disable()">
            </app-input-filed>
            <app-input-filed label="OTP" placeholder="Enter otp" [allowedType]="'number'"
                [control]="$any(createForm).controls['otp']" [starIcon]="'*'" [maxDigits]="6"></app-input-filed>
            <div *ngIf="errorMessage">
              <small class="text-center text-danger">{{ errorMessage }}</small>
            </div>
            <div class="move-button">
              <app-common-button buttonClass="smallButton" buttonLabel="Submit Otp" [loadingIcon]="verifyLoader" (click)="submitOtp()"
                [disabled]="createForm.invalid"></app-common-button>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
