<section class="testimonials-section">
    <img class="quoteup" src="../../assets/images/quoteup.png" alt="Opening quote icon">
    <header class="testimonial-header">
        <h2>OUR TESTIMONIALS</h2>
        <h1>What Our Customers Say</h1>
    </header>
    <div class="testimonials-container customer">
        <ngb-carousel [interval]="2000" [showNavigationArrows]="false" [showNavigationIndicators]="true">
            <ng-template ngbSlide *ngFor="let testimonialGroup of paginatedData">
                <div class="testimonial-group">
                    <div class="testimonial-wrapper" *ngFor="let data of testimonialGroup">
                        <div class="testimonial-box">
                            <p class="testimonial-text">{{data.text}}</p>
                        </div>
                        <img class="vector" src="../../assets/images/Vector 2.svg">
                        <div class="testimonial-author">
                            <figure>
                                <img [src]="data.image" [alt]="data.name">
                                <figcaption>
                                    <h3>{{data.name}}</h3>
                                    <p>Customer</p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <img class="quotedown" src="../../assets/images/quotedown.png" alt="Closing quote icon">
</section>
