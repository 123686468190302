import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../../services/property/property.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-submodule-data',
  templateUrl: './submodule-data.component.html',
  styleUrl: './submodule-data.component.scss'
})
export class SubmoduleDataComponent implements OnInit{
  loader                : boolean = false;
  subModuleList         : any;
  isOverlayOpen         : boolean = false;
  selectedTypeData      : any;
  @Output() updateData  : EventEmitter<any> = new EventEmitter();
  @Output() editSubModule  : EventEmitter<any> = new EventEmitter();
  public readonly faEdit = faEdit;

  constructor(
    private propertyService: PropertyService
  ) { }


  ngOnInit(): void {
    this.getsubactegoryList('');
    this.propertyService.getSubModule().subscribe((res:any)=>{
      if(res=='submodule'){
        this.getsubactegoryList('');
      }
    })
  }

  openOverlay(type: string, selectedRow: any) {
   this.editSubModule.emit(selectedRow);
  }

  getsubactegoryList(categoryId: string) {
    this.loader = true;
    this.propertyService.getsubactegoryList(categoryId)?.subscribe({
      next: (res: any) => {
        this.loader = false;
        this.subModuleList = res?.data;
      },
      error: (error: any) => {
        console.log(error);
        this.loader = false;
      }
    });
  }

}
