import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '../../../services/property/property.service';
import { faCloudUploadAlt, faEdit, faPen, faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-master-data',
  templateUrl: './add-master-data.component.html',
  styleUrl: './add-master-data.component.scss'
})
export class AddMasterDataComponent implements OnChanges, OnInit {
  assetFormGroup           !: FormGroup;
  selectedDetails           : any;
  isEdit                    : boolean = false;
  loader                    : boolean = false;
  moduleList                : any;
  errorMessage              : string = '';
  categoryId                : any;
  profileImage              : any;
  errorMessage1             : any;
  documents                 : any[] = [];

  public readonly userCircleIcon      = faUserCircle;
  protected readonly faCloudUploadAlt = faCloudUploadAlt;
  public readonly faEdit              = faEdit;
  public readonly faPen               = faPen;

  @Input() selectedType     : any;
  @Input() selectedTypeData : any;
  @Output() closePanel      : EventEmitter<any> = new EventEmitter();

  constructor(private _fb: FormBuilder,
    private propertyService: PropertyService
  ) {
    this.createFormGroup();
  }

  ngOnInit(): void {
    if (this.selectedType === 'submodule') {
      this.getModulesList();
    }
    this.assetFormGroup.get('category')?.valueChanges.subscribe((_id) => {
      if (_id) {
        this.categoryId=_id;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTypeData']?.currentValue) {
      this.isEdit = true;
      this.selectedDetails = changes['selectedTypeData']?.currentValue;
      this.patchAsset();
    }
  }

  patchAsset(): void {
    if (this.selectedDetails?.name) {
      this.assetFormGroup.patchValue({
        name: this.selectedDetails.name,
        category: this.selectedDetails.category,
        documents: this.selectedDetails.documents
      });

      if (this.selectedDetails?.categoryDocs && this.selectedDetails.categoryDocs.length > 0) {
        this.profileImage = this.selectedDetails?.categoryDocs[0]?.objectUrl;
      } else {
        this.profileImage = null;
      }
    }
  }
  
  createFormGroup() {
    this.assetFormGroup = this._fb.group({
      name: new FormControl('', [Validators.required]),
      category: new FormControl('', this.selectedType === 'submodule' ? [Validators.required] : []),
      documents: new FormControl('', this.selectedType === 'module' ? [Validators.required] : [])
    });
  }

  onCloseIconClick(value: any) {
    this.closePanel.emit(value);
    this.assetFormGroup.reset();
  }

  getModulesList() {
    this.loader = true;
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.loader = false;
        this.moduleList = res?.data;
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addAssets() {
    this.propertyService.addAssets({
      ...this.assetFormGroup.value
    }).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editAssests() {
    this.propertyService.editAssets(this.assetFormGroup.value, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addModule() {
    let obj: any = {
      name: this.assetFormGroup.value.name,
      documents: this.documents
    }
    this.propertyService.addModule(obj).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
        this.propertyService.setModule(this.selectedType);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editModule() {
    let payload : any ={
      name : this.assetFormGroup.value.name,
      documents : this.documents
    }
    this.propertyService.editModule(payload,this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
          this.propertyService.setModule(this.selectedType);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addSubModule() {
    this.propertyService.addSubModule({
      ...this.assetFormGroup.value,
    }, this.categoryId).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
        this.propertyService.setSubModule(this.selectedType);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editSubModule() {
    this.propertyService.editSubModule(this.assetFormGroup.value, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
          this.propertyService.setSubModule(this.selectedType);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  onSubmit() {
    if (this.assetFormGroup.valid) {
      this.loader = true;
      if (this.selectedType === 'assets') {
        if (this.isEdit) {
          this.editAssests();
        } else {
          this.addAssets();
        }
      }
      if (this.selectedType === 'module') {
        if (this.isEdit) {
          this.editModule();
        } else {
          this.addModule();
        }
      }
      if (this.selectedType === 'submodule') {
        if (this.isEdit) {
          this.editSubModule();
        } else {
          this.addSubModule();
        }
      }
    }
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      if (file.size / 1024 <= 5000) {
        this.propertyService.uploadFiles(formData).subscribe({
          next: (res: any) => {
            this.profileImage = res.data.objectUrl;
            this.documents.push(res.data._id);
          },
          error: (error: any) => {
            this.errorMessage = 'Failed to upload profile picture. Please try again';
            this.errorMessage1 = '';
          }
        });
      } else {
        this.errorMessage1 = 'Image size should be less than 25MB';
      }
    }
  }

 
}
