import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-common-button',
  templateUrl: './common-button.component.html',
  styleUrl: './common-button.component.scss'
})
export class CommonButtonComponent {
  @Input() buttonClass !: string;
  @Input() buttonLabel !: string;
  @Input() disableButtonClass !: string;
  @Input() isImg            !: string;
  @Input() faIcon           : any;
  @Input() typeBtn: boolean = false;
  @Input() loadingIcon      : boolean = false;
  @Input() disabled         : boolean = false;
  @Output() clickEvent      : EventEmitter<any> = new EventEmitter<void>();

  protected readonly faSpinner = faSpinner;
  protected readonly faSearch = faSearch;


  onClickEvent(event: any) {
    if (!this.loadingIcon && !this.disabled) {
      this.clickEvent.emit();
    }
  }
}
