import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../../services/property/property.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-module-data',
  templateUrl: './module-data.component.html',
  styleUrl: './module-data.component.scss'
})
export class ModuleDataComponent implements OnInit {
  loader                : boolean = false;
  moduleList            : any;
  isOverlayOpen         : boolean = false;
  selectedTypeData      : any;
  @Output() editModule  : EventEmitter<any> = new EventEmitter();
  @Output() updateData  : EventEmitter<any> = new EventEmitter();
  public readonly faEdit = faEdit;

  constructor(
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    this.getModulesList();
    this.propertyService.getModule().subscribe((res:any)=>{
      if(res=='module'){
        this.getModulesList();
      }
    })
  }

  openOverlay(type: string, selectedRow: any) {
    this.editModule.emit(selectedRow);
  }

  getModulesList() {
    this.loader = true;
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.loader = false;
        this.moduleList = res?.data;
      },
      error: (error: any) => {
        console.log(error);
        this.loader = false;
      }
    });
  }
}
