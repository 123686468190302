<button
    [ngClass]="disabled ? ( disableButtonClass || 'disabledBtn') : (typeBtn? [buttonClass, 'custButtonClass'] : buttonClass)"
    (click)="onClickEvent($event)" >
    <span *ngIf="!loadingIcon">
        <img *ngIf="isImg" class="img-google" src="{{isImg}}" alt="">
        <fa-icon *ngIf="faIcon" [icon]="faIcon" class="icon"></fa-icon>
        {{buttonLabel}}
      </span>
      <span *ngIf="loadingIcon">
        <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
      </span>
    </button>