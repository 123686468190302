<span  class="{{className}}" data-bs-toggle="tooltip" ngbTooltip="{{toolTip}}" placement="{{placement}}"  (click)="onClickEvent($event)">
    <img *ngIf="!loadingIcon && iconSrc" src="{{iconSrc}}" alt=""/>
    <span *ngIf="!loadingIcon && faIcon" >
        <fa-icon [icon]="faIcon"></fa-icon>
    </span>
    <span *ngIf="!loadingIcon && text">
      {{text}}
    </span>
    <fa-icon *ngIf="loadingIcon" [icon]="faSpinner" [animation]="'spin'"></fa-icon>
</span>
                    
