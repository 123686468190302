<div class="container-fluid overlay_filter">
    <div class="row justify-content-between">
        <!-- Property Type Dropdown -->
        <div class="col">
            <div class="addProperty__dropdown">
                <label class="addProperty__dropdownLabel custom-dropdown-container">Property Type</label>
                <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow"
                    [(ngModel)]="selectedPropertyType">
                    <option [value]="null" >Select Property</option>
                    <option *ngFor="let category of propertyList" [ngValue]="category?.name">
                        {{ category.name }}
                    </option>
                </select>
            </div>
        </div>
        <!-- Location Autocomplete -->
        <div class="col">
            <div class="addProperty__dropdown">
                <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode" label="Location"
                    [labelClass]="'filter__dropdownLabel'" [initialLocation]="selectedLocation" [showStar]="false"
                    className="'filter-maps-irr filter-maps-irr-error'">
                </AutocompleteComponent>
            </div>
        </div>
        <!-- Budget Dropdown -->
        <div class="col">
            <div class="addProperty__dropdown">
              <label class="addProperty__dropdownLabel">Budget</label>
              <select class="addProperty__dropdownOptions custom-dropdown-arrow"
                      [(ngModel)]="selectedBudget"
                      (change)="onBudgetChange($event)">
                <option [value]="null">Select Budget</option>
                <option *ngFor="let budget of budgetOptions"  [value]="budget.value ">
                  {{ budget.label }}
                </option>
              </select>
            </div>
          </div>
        <!-- Amenities Dropdown -->
        <div class="col">
            <div class="custom-dropdown addProperty__dropdown">
                <label class="addProperty__dropdownLabel">Amenities</label>
                <div class="dropdown-header addProperty__dropdownOptions custom-dropdown-arrow"
                    (click)="toggleDropdown()">
                    <span *ngIf="selectedAmenities.length === 0">Select Amenities</span>
                    <span *ngIf="selectedAmenities.length === 1">{{ selectedAmenities[0] }}</span>
                    <span *ngIf="selectedAmenities.length > 1">{{ selectedAmenities[0] }} +{{ selectedAmenities.length -
                        1 }} more</span>
                </div>
                <div class="dropdown-list" *ngIf="dropdownOpen">
                    <div *ngFor="let amenity of amenities" class="dropdown-item propet-s">
                        <label>
                            <input type="checkbox" [checked]="isSelected(amenity)" (change)="toggleSelection(amenity)">
                            {{ amenity }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Popularity Dropdown -->
        <div class="col">
            <div class="addProperty__dropdown">
                <label class="addProperty__dropdownLabel">Popularity</label>
                <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow custom-select"
                    [(ngModel)]="selectedPopularity" (change)="onPopularityChange($event)">
                    <option [value]="null">Select Popularity</option>
                    <option [value]="'mostViewedAsc'">Most Viewed (Low to High)</option>
                    <option [value]="'mostViewedDesc'">Most Viewed (High to Low)</option>
                </select>
            </div>
        </div>
        <!-- Search Button -->
        <div class="col-auto mt-4">
            <app-common-button (click)="navigateToProperties()" [faIcon]="faSearch" buttonClass="smaiifilledbutton"
                buttonLabel="Search"></app-common-button>
        </div>
    </div>
</div>