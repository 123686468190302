import { Component, HostListener, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faChevronDown, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '../../services/property/property.service';
import { FilterService } from '../../services/filters/filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnChanges {
  propertyList: any;
  public readonly faSearch = faSearch;
  public readonly faChevronDown = faChevronDown;
  dropdownOpen = false;
  selectedAmenities: string[] = [];
  locationName: any;
  longitude: any;
  latitude: any;
  amenitiesdata: any;
  line1: any;
  line2: any;
  locality: any;
  city: any;
  state: any;
  amenities: any;
  sortDir: any;
  popularity: any;
  @Input() filterObject: any;
  @Output() searchEvent = new EventEmitter<string>();
  budgetDropdownOpen = false;
  selectedBudget: any = null;
  selectedBudgetLabel: string | null = null;
  price_min:any;
  price_max:any;
  budgetOptions = [
    { value: '0-1000000', label: '0-10L' },
    { value: '1000000-2000000', label: '10L-20L' },
    { value: '2000000-3000000', label: '20L-30L' },
    { value: '3000000-4000000', label: '30L-40L' },
    { value: '4000000-5000000', label: '40L-50L' },
    { value: '5000000-6000000', label: '50L-60L' },
    { value: '6000000-7000000', label: '60L-70L' },
    { value: '7000000-8000000', label: '70L-80L' },
    { value: '8000000-9000000', label: '80L-90L' },
    { value: '9000000-10000000', label: '90L-1Cr' },
  ];
  selectedPropertyType: string | null = null;
  selectedLocation: string = '';
  selectedPopularity: string | null = null;
  public readonly faArrowDown = faArrowDown;

  constructor(
    private router: Router,
    private propertyService: PropertyService,
    private elementRef: ElementRef,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.getCategoryList();
    this.getAmenitiesList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterObject'] && changes['filterObject'].currentValue) {
      const currentValuesData = changes['filterObject'].currentValue;
      if (currentValuesData) {
        this.selectedPropertyType = currentValuesData.propertyType || this.selectedPropertyType;
        this.selectedAmenities = currentValuesData.amenities || this.selectedAmenities;
        this.selectedBudget = currentValuesData.budget || this.selectedBudget;
        if (currentValuesData.popularity && currentValuesData.sortDir !== undefined) {
          this.selectedPopularity = this.mapPopularityValue(currentValuesData.popularity, currentValuesData.sortDir);
        }
        if (currentValuesData.line1) {
          this.selectedLocation = currentValuesData.line1;
        } else if (currentValuesData.line2) {
          this.selectedLocation = currentValuesData.line2;
        } else {
          this.selectedLocation = [currentValuesData.locality, currentValuesData.city, currentValuesData.state]
            .filter(part => part)
            .join(', ');
        }
      }
    }
  }

  navigateToProperties() {
    const filterObject = this.constructFilterObject();
    if (this.selectedPopularity === null) {
      delete filterObject.popularity;
    }
    filterObject.sortDir = this.sortDir;
    const filteredValues = Object.keys(filterObject).reduce((acc: any, key) => {
      if (filterObject[key] !== null && filterObject[key] !== undefined) {
        acc[key] = filterObject[key];
      }
      return acc;
    }, {});
   
    this.searchEvent.emit(filteredValues)
  }

  getCategoryList() {
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.propertyList = res?.data;
      },
      error: (error: any) => {
        console.error('Error fetching category list:', error);
      }
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  isSelected(amenity: string): boolean {
    return this.selectedAmenities.includes(amenity);
  }

  toggleSelection(amenity: string) {
    const index = this.selectedAmenities.indexOf(amenity);
    if (index === -1) {
      this.selectedAmenities.push(amenity);
    } else {
      this.selectedAmenities.splice(index, 1);
    }
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const dropdownElement = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if (!dropdownElement.contains(target)) {
      this.dropdownOpen = false;
    }
  }

  formatAddress(event: any) {
   
      this.handlePropertyAddress(event);
  
  }

  handlePropertyAddress(address: any) {
    this.latitude = address?.location?.length > 0? address?.location[0]: '';
    this.longitude = address?.location?.length > 0?address?.location[1]: '';
    this.line1 = address?.line1;
    this.line2 = address?.line2;
    this.locality = address?.locality;
    this.city = address?.city;
    this.state = address?.state;
  }

  private constructFilterObject(): any {
    return {
      propertyType: this.selectedPropertyType,
      line1: this.line1,
      line2: this.line2,
      locality: this.locality,
      city: this.city,
      state: this.state,
      longitude: this.longitude,
      latitude: this.latitude,
      budget: this.selectedBudget,
      price_max: this.price_max,
      price_min:  this.price_min,
      popularity: this.popularity,
      amenities: this.selectedAmenities.length > 0 ? this.selectedAmenities : undefined
    };
  }

  getAmenitiesList() {
    this.propertyService.getAmenitiesList()?.subscribe({
      next: (res: any) => {
        this.amenitiesdata = res?.data;
        this.amenities = this.amenitiesdata.map((item: any) => item.name);
      },
      error: (error: any) => {
        console.error('Error fetching amenities list:', error);
      }
    });
  }

  onPopularityChange(event: any) {
    const value = event.target.value;
    if (value === 'null') {
      this.selectedPopularity = null;
      this.popularity = null;
      this.sortDir = null;
    } else if (value === 'mostViewedAsc') {
      this.popularity = 'mostViewed';
      this.sortDir = 1;
    } else if (value === 'mostViewedDesc') {
      this.popularity = 'mostViewed';
      this.sortDir = -1;
    }
  }

  private mapPopularityValue(popularity: string, sortDir: number): string | null {
    if (popularity === 'mostViewed' && sortDir === 1) {
      return 'mostViewedAsc';
    } else if (popularity === 'mostViewed' && sortDir === -1) {
      return 'mostViewedDesc';
    }
    return null;
  }

  //budget
  toggleBudgetDropdown() {
    this.budgetDropdownOpen = !this.budgetDropdownOpen;
  }

  isBudgetSelected(budget: any):boolean {
    return this.selectedBudget && this.selectedBudget.min === budget.min && this.selectedBudget.max === budget.max;
  }

onBudgetChange(event: any) {
  const selectedValue = event.target.value;
  if (selectedValue === 'null') {
    this.selectedBudget = null;
    this.selectedBudgetLabel = 'Select Budget';
    this.price_min = null;
    this.price_max = null;
  } else {
    this.selectedBudget = selectedValue;
    this.selectedBudgetLabel = this.selectedBudget ? this.selectedBudget.label : 'Select Budget';
    if (selectedValue) {
      const [min, max] = selectedValue.split('-').map((val: any) => parseInt(val, 10));
      this.price_min = min;
      this.price_max = max;
    } else {
      this.price_min = null;
      this.price_max = null;
    }
  }
}

}