<div class="col-md-12">
  <div *ngIf="loader">
    <div class="d-flex justify-content-center" style="color: #4682B4;">
      <app-common-icon [loadingIcon]="loader"></app-common-icon>
    </div>
  </div>

  <div class="card mb-3" *ngFor="let enquiry of allList">
    <div class="row g-0">
      <div class="col-md-4 image-wraper">
      <div  class="image">
        <ng-container *ngIf="enquiry?.user?.profileUrl?.objectUrl; else nameInitial">
          <img [src]="enquiry?.user?.profileUrl?.objectUrl">
        </ng-container>
        <ng-template #nameInitial>
          <div class="name-initial"> {{ getInitials(enquiry?.user?.name) || "-"}}</div>
        </ng-template>
        <p class="name">{{enquiry?.user?.name}}</p>
        <p class="mobile">+91-{{enquiry?.phoneNumber}}</p>
      </div>
    </div>
      <div class="col-md-8">
        <div class="card-data-block">
          <p class="card-text card-projectname">{{enquiry?.propertyId?.projectName}}</p>
          <p class="card-text">{{enquiry?.created | date:"dd MMM yy" }}</p>
        </div>
        <div class="row">
          <p class="card-text card-message">{{enquiry?.message}}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loader && allList?.length === 0">
    <div class="pt-3 text-danger d-flex justify-content-center">
      No List Found!
    </div>
  </div>
</div>