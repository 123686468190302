<div class="box ">
    <p class="header">{{propertyCount}} Results</p>
    <div class="custom-filter-styles">
        <app-filter [filterObject]="filterObject" (searchEvent)="searchProperty($event)"></app-filter>
    </div>
    <div [ngClass]="{'gap-logged-out': !checkLoginStatus, 'gap-logged-in': checkLoginStatus}" class="property-card"
        infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" [scrollWindow]="false"
        (scrolled)="onScroll()">
        <div *ngFor="let value of propertyList">
            <div class="card img-cards"
                [ngClass]="{'img-card-small': !checkLoginStatus, 'img-card-medium': checkLoginStatus}">
                <ngb-carousel #carousel [interval]="2000" [showNavigationIndicators]="false"
                    [showNavigationArrows]="value.propertyDocs.length > 1">
                    <ng-template *ngFor="let item of value.propertyDocs; index as imageIndex" ngbSlide>
                        <img class="carousel-image" src="{{item.objectUrl}}" alt="..." width="100%" height="115px">
                    </ng-template>
                </ngb-carousel>
                <div class="card-body">
                    <p class="title">{{value.projectName}}</p>
                    <p class="size">Property Size: <span class="size">{{value.propertysize}}</span></p>
                    <p class="price">₹ {{value.formattedPrice}}</p>
                    <p class="location">Location: <span class="location">{{value.address.city}}</span></p>
                    <div class="buttons">
                        <div>
                            <app-common-button buttonClass="view-project" buttonLabel="View Project"
                                (clickEvent)="openProjectView(value)"></app-common-button>
                        </div>
                        <div class="enquiry" *ngIf="!isAdmin">
                            <app-common-button buttonClass="view-enquiry" buttonLabel="Enquiry"
                                (clickEvent)="openEnquiry(value)"></app-common-button>
                        </div>
                        <div *ngIf="isAdmin && checkLoginStatus">
                            <app-common-button buttonClass="view-enquiry"
                                buttonLabel="Enquiry List ({{value.enquiresCount || 0}})"
                                (clickEvent)="openEnquiryList(value)"></app-common-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="verifyLoader" class="loader">
            <app-common-icon [loadingIcon]="verifyLoader"></app-common-icon>
        </div>
    </div>
</div>
<app-enquiry-popup [propertyId]="selectedPropertyId" [isEnquiry]="isEnquiry"
    (closeModal)="closeEnquiry()"></app-enquiry-popup>
<div *ngIf="isEnquiryList">
    <app-enquiry-list [isEnquiryList]="isEnquiryList" [propertyId]="selectedPropertyId"
        (closeModal)="closeEnquiryList()"></app-enquiry-list>
</div>
<div *ngIf="!checkLoginStatus">
    <app-login-popup [isVisible]="isLoginModalVisible" (closeModal)="closeProjectModal()"></app-login-popup>
</div>
