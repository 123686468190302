import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardCountService {

  constructor(private http: HttpClient
  ) { }

  categoryDashboardCount(): Observable<any> {
    let url = environment.API_URL + '/v1/property/dashboard/categoryCount';
    return this.http.get(url);
  }

  usersCount() : Observable <any>{
    let url = environment.API_URL + '/v1/oauth/user-count';
    return this.http.get(url);
  }

  getDashboardFilterEnquiry(queryparams: any): Observable<any>{
    return this.http.get(`${environment.API_URL}/v1/property-enq/dashboard/filterEnquiry`,{
      params: queryparams
    });
  }
}
