import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { PropertyService } from '../../services/property/property.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {

  contactUsForm!   : FormGroup;
  showResponseData : boolean = false;
  errorMessage     : any;
  message          : any;

  constructor(private fb: FormBuilder,
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    this.generateContactForm();
  }

  generateContactForm() {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(RegExpPatterns.name)]],
      email: ['', [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)]],
      phoneNumber: ['', [
        Validators.required,
        Validators.pattern(RegExpPatterns.MobilePattern),
      ]],
    })
  }

  addContactusDetails() {
    const payload = {
      name: this.contactUsForm.value.name,
      email: this.contactUsForm.value.email,
      phoneNumber: this.contactUsForm.value.phoneNumber
    }
    this.propertyService.addContactusDetails(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.contactUsForm.reset();
          this.message = 'Thank You. We Will Conatct You Soon';
          this.clearErrorMessageAfterTimeout();
        }
      },
      error: (error: any) => {
        this.errorMessage = error?.error?.error?.message;
      },

    })
  }

  clearErrorMessageAfterTimeout() {
    setTimeout(() => {
      this.message = '';
    }, 3000);
  }
}
