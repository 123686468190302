import { Component } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrl: './home-layout.component.scss'
})
export class HomeLayoutComponent {

  isLogin : boolean = false;
  status  : any;
  isAdmin : boolean = false;
  route   : any;

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {
    this.router.events.subscribe((event: any) => { 
      if (event instanceof NavigationEnd) { 
        this.getDataFromLocalStorage();
        this.route = event.urlAfterRedirects?.split('?')[0]; 
      } 
    });
  }

  ngOnInit() {
    this.getDataFromLocalStorage();
    this.getLocalStorageDetails();
  }

  getLocalStorageDetails() {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    this.isAdmin = this.status === 'Admin';
  }

  getDataFromLocalStorage(){
    if (this.storageService?.getDataFromLocalStorage(
      StorageKeys.ACCESS_TOKEN
    )) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

}
