import { Component } from '@angular/core';
import { StorageService } from '../services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'property-box';
  isLogin : boolean = false;

  constructor(
    private storageService: StorageService
  ){}

  ngOnInit(){
  }
}
