<div class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" [class.show]="dateRange">
  <div class="modal-dialog custom-modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>Select Date Range</strong></p>
        <img class="img-move" src="../../assets/images/icons8-cancel.svg" (click)="close()">
      </div>
      <div class="modal-body">
        <div *ngIf="loader">
          <div class="d-flex justify-content-center" style="color: #4682B4;">
            <app-common-icon [loadingIcon]="loader"></app-common-icon>
          </div>
        </div>
        <div *ngIf="!loader">
          <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
            outsideDays="hidden" [maxDate]="maxDate">
          </ngb-datepicker>

          <ng-template #t let-date let-focused="focused">
            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)" [class.future]="isFuture(date)"
              (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>