import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage/storage.service';
import { StorageKeys } from '../enums/storage.enum';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService, private router: Router) { }

  handleError = (error: any) => {
    if (error.status === 401 || error.statusCode === 401) {

      this.storageService.clearLocalStorage();
      this.router.navigate(['/login']);
    }
    return throwError(() => error);
  };

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.storageService.getDataFromLocalStorage(
      StorageKeys.ACCESS_TOKEN
    );
    const userDetails = this.storageService.getDataFromLocalStorage(
      StorageKeys.SELF_DETAILS
    );
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', `Bearer ${token}`);
      // if (userDetails?.company) {
      //   headers = headers.set("presentCompany", userDetails.company);
      // }
      request = request.clone({
        headers: headers,
      });
    }

    return next.handle(request).pipe(catchError(this.handleError));
  }
}
