import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-animated-count',
  templateUrl: './animated-count.component.html',
  styleUrls: ['./animated-count.component.scss']
})
export class AnimatedCountComponent implements OnChanges, AfterViewInit {

  @Input() duration: number = 1000;
  @Input() digit: number = 0;
  @Input() steps: number = 12;

  @ViewChild('animatedDigit') animatedDigit?: ElementRef;

  animateCount(): void {
    if (typeof this.digit === 'number' && this.animatedDigit) {
      this.counterFunc(this.digit, this.duration, this.animatedDigit);
    }
  }

  counterFunc(endValue: number, durationMs: number, element: ElementRef): void {
    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    const step = () => {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;
      
      if (element) {
        element.nativeElement.textContent = Math.abs(Math.floor(currentValue));
      }

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    };

    step();
  }

  ngAfterViewInit(): void {
    if (this.digit) {
      this.animateCount();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['digit'] && !changes['digit'].isFirstChange()) {
      this.animateCount();
    }
  }
}
