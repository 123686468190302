import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrl: './master-data.component.scss'
})
export class MasterDataComponent implements OnInit, OnChanges {
  tabvalue              : any = 'assets';
  assetsList            : any;
  isOverlayOpen         : boolean = false;
  selectedTypeData      : any;
  tableData             : any;
  loader                : boolean = false;
  @Input() masterData   : any = [];
  @Input() selectedType : string = '';
  @Output() updateData  : EventEmitter<any> = new EventEmitter();
  public readonly faEdit = faEdit;


  ngOnChanges(changes: SimpleChanges): void {
    this.tableData = changes['masterData']?.currentValue
  }

  constructor(
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    this.getassetsList();
    this.tableData = this.masterData;
  }

  openOverlay(type: string, selectedRow: any) {
    this.isOverlayOpen = true;
    this.selectedTypeData = selectedRow;
  }

  closeOverlay(isSaved: boolean) {
    if (isSaved) {
      this.updateData.emit(true);
    }
    this.getassetsList();
    this.isOverlayOpen = false;
  }

  onTabSelect(value: any) {
    this.tabvalue = value;
  }

  getassetsList() {
    this.loader = true;
    this.propertyService.getassetsList()?.subscribe({
      next: (res: any) => {
        this.loader = false;
        this.assetsList = res?.data;
      },
      error: (error: any) => {
        console.log(error);
        this.loader = false;
      }
    });
  }

  editModule(data: any){
    this.openOverlay('edit', data);
  }

  editSubModule(data: any){
    this.tabvalue = 'submodule';
    this.selectedTypeData = data;
    this.isOverlayOpen = true;
  }
}
