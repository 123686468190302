import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  showPropertyItems: boolean = false;
  activeItem: string = '';
  activeSubItem: string = '';

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.setActiveItemByRoute(event.urlAfterRedirects);
    });
  }

  ngOnInit() {
    this.setActiveItemByRoute(this.router.url);
  }

  setActiveItemByRoute(url: string) {
    if (url.includes('dashboard')) {
      this.setActiveItem('dashboard');
    } else if (url.includes('properties-list')) {
      this.setActiveItem('properties', 'profileList');
    } else if (url.includes('add-property')) {
      this.setActiveItem('properties', 'addProperty');
    } else if (url.includes('enquiries')) {
      this.setActiveItem('enquiries');
    } else if (url.includes('masterdata')) {
      this.setActiveItem('metadata');
    } else if (url.includes('user-profile')) {
      this.setActiveItem('profile');
    } else if (url.includes('settings')) {
      this.setActiveItem('settings');
    } else if (url.includes('home')) {
      this.setActiveItem('home');
    }
  }

  propertyList() {
    this.showPropertyItems = !this.showPropertyItems;
  }

  setActiveItem(item: string, subItem?: string) {
    this.activeItem = item;
    if (subItem) {
      this.activeSubItem = subItem;
      this.showPropertyItems = true; 
    } else {
      this.activeSubItem = '';
      this.showPropertyItems = item === 'properties';
    }
  }

  logOut() {
    this.loginService.logOut().subscribe(res => {
      if (res.data) {
      }
    });
    localStorage.clear();
    this.properties();
  }

  addPropertyPage() {
    this.router.navigate(['add-property']);
  }
  
  dashboard() {
    this.router.navigate(['dashboard']);
  }

  enquiries() {
    this.router.navigate(['enquiries']);
  }

  profile() {
    this.router.navigate(['user-profile']);
  }

  properties() {
    this.router.navigate(['properties-list']);
  }

  home() {
    this.router.navigate(['home']);
  }

  masterData() {
    this.router.navigate(['masterdata']);
  }
}
