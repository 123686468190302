<div  *ngIf="!isLogin || (route === '/home' || route === '/privacy-policy' ) || !isAdmin">
    <div>
        <app-nav-bar></app-nav-bar>
    </div>
    <div>
        <router-outlet></router-outlet>
    </div>
</div>

<div *ngIf="isAdmin && isLogin && route !== '/home'  && route !== '/privacy-policy'">
    <div class="d-flex justify-content-start" >
        <div>
            <app-side-bar></app-side-bar>
        </div>
        <div class="main-div">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

