import { Component } from '@angular/core';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '../../services/property/property.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {
  userCircleIcon = faUserCircle;
  userDetails: any;
  isProfileEdit: boolean = false;
  isEditUserPanelOpen: boolean = false;
  profileImage: any;
  userName: any;
  phoneNumber: any;
  saveProfile:any;
  payload:any

  constructor(
    private propertyService: PropertyService,
    private storageService: StorageService,

  ) { }

  ngOnInit(): void {
    this.userDetails = {
      name: this.storageService.getDataFromLocalStorage(
        StorageKeys.NAME
      ),
      phoneNumber: this.storageService.getDataFromLocalStorage(
        StorageKeys.PHONE_NUMBER
      ),
      profileImage: this.storageService.getDataFromLocalStorage(
        StorageKeys.PROFILE
      ),
      email: this.storageService.getDataFromLocalStorage(
        StorageKeys.EMAIL
      )
    }

  }

  getuserDetails() {
    this.propertyService.getuserDetails()?.subscribe({
      next: (res: any) => {
        this.userDetails = res?.data;
        if(this.userDetails){
          this.userDetails = {
            name: this.storageService.getDataFromLocalStorage(
              StorageKeys.NAME
            ),
            phoneNumber: this.storageService.getDataFromLocalStorage(
              StorageKeys.PHONE_NUMBER
            ),
            profileImage: this.storageService.getDataFromLocalStorage(
              StorageKeys.PROFILE
            ),
            email: this.storageService.getDataFromLocalStorage(
              StorageKeys.EMAIL
            ),
          }
        }
      },
      error: (error: any) => {
      }
    });
  }

  onProfileUpdated() {
     this.userDetails = {
      name: this.storageService.getDataFromLocalStorage(
        StorageKeys.NAME
      ),
      phoneNumber: this.storageService.getDataFromLocalStorage(
        StorageKeys.PHONE_NUMBER
      ),
      profileImage: this.storageService.getDataFromLocalStorage(
        StorageKeys.PROFILE
      )
    }
   
  }
  
  

  toggleEdit() {
    this.isEditUserPanelOpen = true
    document.body.classList.add('panel-open');
  }


  closeAddUserPanel() {
    this.getuserDetails();
    this.isEditUserPanelOpen = false;
    document.body.classList.remove('panel-open');
  }
}
