import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrl: './enquiries.component.scss'
})
export class EnquiriesComponent implements OnInit {

  loader      : boolean = false;
  allList     : any;


  constructor(
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    this.getOverAllEnquiryList();
  }


  getOverAllEnquiryList() {
    this.loader = true;
    this.propertyService.getOverallEnquiryList()?.subscribe({
      next: (res: any) =>{
        this.loader = false;
        this.allList = res?.data;
      },
      error: (error: any) => {
        this.loader = false;
        console.log(error);
      }
    });
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    return initials.length > 2 ? initials.charAt(0) + initials.charAt(1) : initials;
  }

}
