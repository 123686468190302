export enum StorageKeys {
  ACCESS_TOKEN = 'token',
  ROLE = 'role',
  USER_ID = 'userId',
  COMPANY_ID = 'companyId',
  SELF_DETAILS = 'selfDetails',
  PRESENT_COMPANY = 'presentCompany',
  enrollmentType = 'enrollmentType',
  PHONE_NUMBER = 'phoneNumber',
  NAME         = 'name',
  PROFILE     ='profileImage',
  EMAIL      ='email',
  MODULENAME = 'moduleName',
  MODULEID   =  'moduleId'
}
