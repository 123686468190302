import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient

  ) { }

  logIn(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/token';
    return this.http.post(url, payload);
  }

  generateOtp(payload: any): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/generate-login-otp';
    return this.http.post(url, payload);
  }

  submitOtp(payload : any) : Observable<any> {
    let url = environment.API_URL + '/v1/oauth/token-using-otp';
    return this.http.post(url, payload)
  }
  
  logOut(): Observable<any> {
    let url = environment.API_URL + '/v1/oauth/logout';
    return this.http.get(url);
  }
  
}
