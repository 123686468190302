import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InputFiledComponent } from './utilites/input-filed/input-filed.component';
import { CommonButtonComponent } from './utilites/common-button/common-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS,  HttpClientModule } from '@angular/common/http';
import { HttpInterceptorInterceptor } from './share/interceptor/http-interceptor.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PropertyService } from '../services/property/property.service';
import { MasterDataComponent } from './master-data/master-data.component';
import { AddMasterDataComponent } from './master-data/add-master-data/add-master-data.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonIconComponent } from './utilites/common-icon/common-icon.component';
import { ModuleDataComponent } from './master-data/module-data/module-data.component';
import { SubmoduleDataComponent } from './master-data/submodule-data/submodule-data.component';
import { EnquiryPopupComponent } from './enquiry-popup/enquiry-popup.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { InputAutocompleteComponent } from './input.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import { NoLeadingSpacesDirective } from './utilites/no-leading-spaces.directive';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FeaturedPropertiesComponent } from './featured-properties/featured-properties.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { InvestPropertiesComponent } from './invest-properties/invest-properties.component';
import { CustomerReviewsComponent } from './customer-reviews/customer-reviews.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from './footer/footer.component';
import { FilterComponent } from './filter/filter.component';
import { HomeComponent } from './home/home.component';
import { InvestorsReviewsComponent } from './investors-reviews/investors-reviews.component';
import { LoginNewComponent } from './login-new/login-new.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { AddPropertiesComponent } from './add-properties/add-properties.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EnquiriesComponent } from './enquiries/enquiries.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EnquiryListComponent } from './enquiry-list/enquiry-list.component';
import { AnimatedCountComponent } from './utilites/animated-count/animated-count.component';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DashboardPopupComponent } from './dashboard-popup/dashboard-popup.component';



@NgModule({
  declarations: [
    AppComponent,
    InputFiledComponent,
    CommonButtonComponent,
    MasterDataComponent,
    AddMasterDataComponent,
    CommonIconComponent,
    ModuleDataComponent,
    SubmoduleDataComponent,
    SubmoduleDataComponent,
    EnquiryPopupComponent,
    InputAutocompleteComponent,
    SubmoduleDataComponent,
    LoginPopupComponent,
    UserProfileComponent,
    UserProfileEditComponent,
    EnquiryListComponent,
    NoLeadingSpacesDirective,
    NavBarComponent,
    FeaturedPropertiesComponent,
    AboutusComponent,
    InvestPropertiesComponent,
    CustomerReviewsComponent,
    ContactUsComponent,
    FooterComponent,
    FilterComponent,
    HomeComponent,
    InvestorsReviewsComponent,
    LoginNewComponent,
    SideBarComponent,
    HomeLayoutComponent,
    AddPropertiesComponent,
    PropertyListComponent,
    DashboardComponent,
    EnquiriesComponent,
    PropertyDetailsComponent,
    PrivacyPolicyComponent,
    AnimatedCountComponent,
    DashboardPopupComponent,

  ],
  exports: [
    InputFiledComponent,
    CommonButtonComponent,
    CommonIconComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    InfiniteScrollModule,
    GoogleMapsModule,
    NgbDatepicker
],
  providers: [
    // HttpClientModule,
    PropertyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

