import { Component } from '@angular/core';
import { FilterService } from '../../services/filters/filter.service';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent {

  propertyList: any[] = [
    {
      img:"../../assets/images/realtbox-advertisement-img1.jpeg"
    },
    {
      img:"../../assets/images/realtbox-advertisement-img2.jpeg"
    },
    {
      img:"../../assets/images/realtbox-advertisement-img3.jpeg"
    },
    {
      img:"../../assets/images/realtbox-advertisement-img4.jpeg"
    },

    {
      img:"../../assets/images/realtbox-advertisement-img5.jpeg"
    },
   
  ];

  constructor(private filterService: FilterService,
    private router: Router,
    private storageService: StorageService) {

  }
  searchProperties(event: any) {
    this.filterService.updateFilterObject('');
    this.router.navigate(['properties-list', {data: this.storageService.encrypt(JSON.stringify(event))}]);
  }

}
