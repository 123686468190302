import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrl: './aboutus.component.scss'
})
export class AboutusComponent implements OnInit {
  private hasAnimated = false;
  constructor(private renderer: Renderer2 ) { }
  ngOnInit(): void {
    this.animateCounter('customerCounter', 12);
    this.animateCounter('officeCounter', 14);
    this.animateCounter('studentCounter', 10);

  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   const aboutUsSection = document.getElementById('aboutUsSection');
  //   if (aboutUsSection) {
  //     const rect = aboutUsSection.getBoundingClientRect();
  //     if (rect.top < window.innerHeight && rect.bottom > 0) {
  //       this.hasAnimated = true;
  //       if(window.innerHeight == 551){
  //         this.animateCounter('customerCounter', 12);
  //         this.animateCounter('officeCounter', 14);
  //         this.animateCounter('studentCounter', 10);
  //       }
  //     } 
  //   }
  // }

  animateCounter(elementId: string, target: number) {
    const element = this.renderer.selectRootElement(`#${elementId}`);
    element.innerText = '0';
    const duration = 2000; 
    const increment = target / (duration / 50); 
    const updateCounter = () => {
      const count = +element.innerText;
      if (count < target) {
        element.innerText = `${Math.ceil(count + increment)}`;
        setTimeout(updateCounter, 200); 
      } else {
        element.innerText = `${target}+`;
      }
    };
  
    updateCounter();
  }
}

