import { Component } from '@angular/core';

@Component({
  selector: 'app-investors-reviews',
  templateUrl: './investors-reviews.component.html',
  styleUrl: './investors-reviews.component.scss'
})
export class InvestorsReviewsComponent {
  reviews = [
    {
      title: 'Bliss Residency, Adyar',
      description: 'Living at realt box Residency has truly been a delightful experience. The attention to detail in the design and the amenities provided make it more than just an apartment; its a lifestyle. The location in Adyar is perfect, offering both convenience and tranquility',
      name: 'John D.',
      designation: 'Software Engineer',
      image: "assets/images/investors.png"
    },
    {
      title: 'Bliss Residency, Adyar',
      description: 'Living at realt box Residency has truly been a delightful experience. The attention to detail in the design and the amenities provided make it more than just an apartment; its a lifestyle. The location in Adyar is perfect, offering both convenience and tranquility',
      name: 'John D.',
      designation: 'Software Engineer',
      image: "assets/images/investors.png"
    },
    {
      title: 'Bliss Residency, Adyar',
      description: 'Living at realt box Residency has truly been a delightful experience. The attention to detail in the design and the amenities provided make it more than just an apartment; its a lifestyle. The location in Adyar is perfect, offering both convenience and tranquility',
      name: 'John D.',
      designation: 'Software Engineer',
      image: "assets/images/investors.png"
    }
  ];

}
