<div class="navbar-bg m-3">
  <div class="card-body">
    <div class="head">
      <nav class="navbar navbar-expand-lg navbar-light bg-white rounded-pill shadow-sm py-3 px-5">
        <a class="navbar-brand" href="#">
          <img src="../../assets/images/logo.png" alt="Logo" class="logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav mr-3 rounded-pill bg-light px-3">
            <img src="../../assets/images/dollar.png" class="dollar-logo">
            <li class="nav-item">
              <a class="nav-link" href="#" >Get a Loan</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('about')">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('featured')">Featured Properties</a>
            </li>
          </ul>
          <ul *ngIf="!checkLoginStatus" class="navbar-nav  m-2 rounded-pill bg-light px-3 border border-dark">
            <li class="nav-item">
              <a class="nav-link contact" (click)="logIn()">Login</a>
            </li>
          </ul>
          <div class="profile-container" *ngIf="checkLoginStatus">
            <fa-icon [icon]="userCircleIcon" style="color:#fdb827; font-size: 40px; cursor: pointer;margin-left: 10px;"
            class="profile-dropdown-menu"  (click)="toggleMenu()"></fa-icon>
            <div *ngIf="menuVisible" class="profile-menu">
              <a (click)="profile()">Profile</a>
              <a (click)="logOut()">Logout</a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>