<div class="Offcanvas__header">
    <div class="Offcanvas__heading">
        {{ isEdit ? 'Update' : 'Add' }} {{selectedType === 'module' ? 'Module' : selectedType ==='submodule'?'Sub Module': 'Asset'}}
    </div>
    <div class="Offcanvas__Iconscontainer">
        <div class="Offcanvas__cancelIcon">
            <img src="../../../assets/images/icons8-cancel.svg" alt="cancelIcon" (click)="onCloseIconClick(false)">
        </div>
    </div>
</div>
<div class="offCanvas__container">
    <div class="offCanvas__contentWrapper">
        <form class="mt-2" [formGroup]="assetFormGroup">
            <div class="offcanvas__content">
                <div class="offcanvas__orgDetails">
                    <div class="offCanvas__orgName-inputField">
                        <app-input-filed label="Name" placeholder="Enter Name"
                            [control]="$any(assetFormGroup)?.controls['name']" [starIcon]="'*'"></app-input-filed>
                        <div *ngIf="assetFormGroup?.controls?.['name']?.invalid &&
                        (assetFormGroup?.controls?.['name']?.dirty ||
                        assetFormGroup?.controls?.['name']?.touched)">
                            <div *ngIf="assetFormGroup?.controls?.['name']?.errors?.['required']">
                                <small class="text-danger">Name is required.</small>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedType === 'module'">
                        <div class="userDetails__imageContainer">
                            <div class="cursor-pointer card" (click)="file.click()">
                                <input style="display: none" #file type="file" (change)="upload($event)" />
                                <ng-container *ngIf="!profileImage">
                                    <div class="upload-icon text-secondary text-center">
                                        <fa-icon [icon]="faCloudUploadAlt"></fa-icon>
                                    </div>
                                    <div class="file-type-name" style="color: #6c757d">Upload</div>
                                </ng-container>
                                <ng-container *ngIf="profileImage">
                                    <img [src]="profileImage" alt="Profile Image" />
                                    <span class="profile-image-user"><fa-icon [icon]="faEdit"></fa-icon></span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="addProperty__dropdown" *ngIf="selectedType === 'submodule'">
                        <div class="addProperty__dropdownLabel">
                            Module <span style="color:red;">*</span>
                        </div>
                        <select class="addProperty__dropdownOptions" formControlName="category">
                            <option [value]="" selected disabled>Select Module</option>
                            <option *ngFor="let module of moduleList" [ngValue]="module?._id">
                                {{module.name}}
                            </option>
                        </select>
                    </div>
                    <p class="text-danger m-2 mt-5" *ngIf="errorMessage">{{errorMessage}}</p>
                    <p class="text-danger m-2 mt-5" *ngIf="errorMessage1">{{errorMessage1}}</p>
                </div>
            </div>
        </form>
    </div>
    <div class="offCanvas__footer">
        <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Cancel"
            (click)="onCloseIconClick(false)"></app-common-button>
        <app-common-button buttonClass="view-enquiry" [loadingIcon]="loader" buttonLabel="Submit" (click)="onSubmit()"
            [disabled]="assetFormGroup.invalid"></app-common-button>
    </div>
</div>