import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-filed',
  templateUrl: './input-filed.component.html',
  styleUrl: './input-filed.component.scss'
})
export class InputFiledComponent {
 
  @Input() label         : string | undefined;
  @Input() labelClass    : string | undefined;
  @Input() maxDigits  !  : number ;
  @Input() placeholder   : string | undefined;
  @Input() type          : string | undefined;
  @Input () disabled     : boolean =  false ;
  @Input() starIcon      : any;
  @Input() value         : string | undefined;
  @Input()control!       : FormControl;
  @Input() allowedType ! : string;
  @Output() inputEvent   : EventEmitter<any> = new EventEmitter<any>();


  constructor() {
    this.control = new FormControl('');
  }

  onChange(event: any) {
    const digits = this.control?.value?.replace(/\D/g, '');
    if (digits?.length > this.maxDigits) {
      this.control.setValue(digits.slice(0, this.maxDigits));
    }
  }

  onKeyDown(event: KeyboardEvent, val: any) {
    if (this.allowedType === 'chars') {
      const key = event.key;
      const specialCharacterAndNumberRegex = /[!@#$%^&*()+{}[\]:;<>,.?~\\/\d-]/
      if (specialCharacterAndNumberRegex.test(key)) {
        event.preventDefault();
      }
    }
    if (this.allowedType === 'number') {
      if (event.key == 'Backspace') return;
      const key = event;
      var regex = /[0-9]|\./;
      if (!regex.test(event.key)) {
        if (key.preventDefault) key.preventDefault();
      }
    }
  }
}
