<div class=" m-3">
    <div class="btn-div">
        <div>
            <app-common-button (click)="back()" buttonClass="smallbuttoncancel" buttonLabel="Back"></app-common-button>
        </div>
    </div>
    <div>
        <div class="prop-card">
            <div class="prop-heading-div">
                <p class="prop-heading">Property Details</p>
            </div>
            <div class="prob-content">
                <div class="row">
                    <div class="col-md-8">
                        <div *ngIf="verifyLoader ">
                            <div class="loader">
                                <app-common-icon [loadingIcon]="verifyLoader"></app-common-icon>
                            </div>
                        </div>
                        <ngb-carousel #carousel [interval]="3000" [showNavigationIndicators]="false"
                            [showNavigationArrows]="projectDetails?.propertyDocs.length > 1">
                            <ng-template *ngFor="let item of projectDetails?.propertyDocs; index as imageIndex"
                                ngbSlide>
                                <div class="image">
                                    <img class="d-block w-100" src="{{item.objectUrl}}" alt="...">
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                    <div class="col-4">
                        <div class="sale-card">
                            <p class="sale-text">SALE</p>
                            <p class="sale-text">&#8377; {{ projectDetails?.formattedPrice }}</p>
                        </div>
                        <div class="features-card">
                            <p class="features-heading">Addtional Features</p>
                            <ul *ngIf="projectDetails?.advanceFeatures">
                                <li *ngFor="let feature of projectDetails?.advanceFeatures?.amenity" class="features">
                                  {{ feature }}
                                </li>
                              </ul>
                        </div>
                    </div>
                </div>
                <div class="property-details">
                    <div class="property-data">
                        <p class="property-heading">{{projectDetails?.projectName}}</p>
                        <p class="property-text">{{ projectDetails?.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>