import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { faMessage, faPhone,faUser,faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-enquiry-list',
  templateUrl: './enquiry-list.component.html',
  styleUrl: './enquiry-list.component.scss'
})
export class EnquiryListComponent implements OnInit {
  enquiryList: any ;
  count: number = 0;
  public readonly faMobile = faPhone;
  public readonly faMessage = faMessage  ;
  public readonly faUserCircle = faUser  ;
  public readonly faUser = faUser  ;
  loader                : boolean = false;

  @Input() isEnquiryList : boolean = false;
  @Input() propertyId: any; 
  @Output()closeModal: EventEmitter<void> = new EventEmitter<void>();


  constructor(
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
    this.getEnquiryList(this.propertyId);
  }

  close() {
    this.closeModal.emit();
  }



  getEnquiryList(id: any) {
    this.loader = true;
    this.propertyService.getEnquiryList(id)?.subscribe({
      next: (res: any) =>{
        this.loader = false;
        this.enquiryList = res?.data;
      },
      error: (error: any) => {
        this.loader = false;
        console.log(error);
      }
    });
  }


  getInitials(name: string): string {
    if (!name) {
      return '';
    }
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    return initials.length > 2 ? initials.charAt(0) + initials.charAt(1) : initials;
  }
}
