import { Component, EventEmitter, Input, Output, inject, OnChanges, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.scss']
})

export class DashboardPopupComponent implements OnChanges {
  loader                      : boolean = false;
  @Input() dateRange          : boolean = false;
  @Output() closeModal        : EventEmitter<void> = new EventEmitter<void>();
  @Output() dateRangeSelected : EventEmitter<{ fromDate: NgbDate, toDate: NgbDate | null }> = new EventEmitter<{ fromDate: NgbDate, toDate: NgbDate | null }>();

  calendar = inject(NgbCalendar);

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate = this.calendar.getToday();
  toDate: NgbDate | null = null;
  maxDate: NgbDate = this.calendar.getToday();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dateRange'] && changes['dateRange'].currentValue === true) {
      this.resetDates();
    }
  }

  onDateSelection(date: NgbDate) {
    const today = this.calendar.getToday();
    if (date.after(today)) {
      return;
    }

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
      this.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  close() {
    this.dateRange = false;
    this.closeModal.emit();
  }

  resetDates() {
    this.fromDate = this.calendar.getToday();
    this.toDate = null;
    this.hoveredDate = null;
  }

  isFuture(date: NgbDateStruct): boolean {
    const today = new Date();
    const dateToCheck = new Date(date.year, date.month - 1, date.day);
    return dateToCheck > today;
  }
}
